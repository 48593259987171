@import 'common/scss/utils.scss';

#admin-manage-examination-content {
  #admin-manage-examination-setting {
    @include flex(initial, initial);
    flex-direction: column;
    gap: 8px;
  }
  .examination-setting-disabled {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: $surface-100;
    opacity: 0.7;
  }
}
