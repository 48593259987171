@import 'common/scss/utils.scss';

.date-time-input {
  @include flex(center, initial);
  gap: 4px;

  .ubt__select {
    height: 36px;

    .ubt__select__summary {
      padding: 4px;
      border-radius: 4px;
      min-width: initial;

      .ubt__select__summary__title {
        font-size: 14px;
      }
    }
  }
  .ubt__popover__poper {
    .ubt__popover__poper__content {
      .time-option {
        & > div {
          font-size: 14px;
          padding: 0;
          @include flex(center, center);
        }
      }
    }
  }
}
