@import 'common/scss/utils.scss';

section.reviewer-result-table-tr-expand {
  @include flex(initial, initial);
  gap: 6px;
  border-top: 3px solid $primary-5;
  // padding: 26px 30px;
  height: 100%;
  background-color: #f9f9ff;

  &.no-gap {
    gap: 0;
  }

  .content-wrapper {
    width: 100%;
    padding: 24px 0 24px 72px;
    border-radius: 8px;
    overflow: hidden;
    background-color: $surface-100;
    border-radius: 0;

    & > ul {
      & > li:not(:last-child) {
        border-bottom: 1px solid $gray-200;
      }
      & > li:not(:first-of-type) {
        padding-top: 24px;
      }
      li {
        .title {
          @include flex(center, initial);
          font-size: 18px;
          font-weight: 500;
          color: $primary-100;

          & > span {
            margin-right: 4px;
          }
        }
      }
    }
  }

  & > article {
    flex-shrink: 0;
    width: 270px;
    padding: 30px 26px;
    background-color: $surface-100;

    & > div > div {
      margin-bottom: 40px;
      h4 {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;

        span {
          font-weight: 400;
        }
      }

      &.reject {
        p {
          color: #f53c3c;
        }
      }
    }
  }
}
