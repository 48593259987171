@import 'common/scss/utils.scss';

.new-info-wrap {
  margin-top: 32px;
  margin-bottom: 32px;
  h4 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
    color: $surface-medium;
    margin-bottom: 8px;
  }
  & > .flex-box {
    @include flex(center, initial);
    gap: 8px;
    & > .column {
      flex: 1;
      border: 1px solid $primary-20;
      background: $surface-100;
      border-radius: 8px;
      padding: 16px;
      .title {
        @include flex(center, initial);
        gap: 8px;
        margin-bottom: 8px;
        & > h5 {
          color: $surface-high;
          font-size: 16px;
          font-weight: 500;
        }
        .id-icon {
          background: kyc-icon(0px, -400px);
          width: 26px;
          height: 18px;
        }
        .account-icon {
          background: kyc-icon(-33px, -400px);
          width: 20px;
          height: 20px;
        }
        .facial-icon {
          background: kyc-icon(-60px, -400px);
          width: 20px;
          height: 20px;
        }
        .customerrisk-icon {
          background: kyc-icon(-87px, -400px);
          width: 20px;
          height: 20px;
        }
      }
      & > p {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
