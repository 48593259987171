@import 'common/scss/utils.scss';

#kyc-page-header {
  @include flex(center, center);
  width: 100%;
  height: 70px;
  background-color: $surface-100;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 8px, rgb(0 0 0 / 5%) 0px 2px 8px 4px !important;

  .header-content {
    @include flex(center, initial);
    gap: 24px;
    width: 100%;
    height: 100%;
    max-width: 1080px;

    i.logo {
      @include flex(center, center);
      flex-shrink: 0;
      width: 102px;
      height: 100%;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 16px;
        background: kyc-icon(-8px, -519px);
      }
    }

    .navigator {
      @include flex(center, initial);
      height: 100%;

      li {
        @include flex(center, center);
        padding: 0 16px;
        height: inherit;

        a {
          @include flex(center, center);
          width: inherit;
          height: inherit;
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.54);

          &.active {
            position: relative;
            color: $primary-100;

            &::after {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 4px;
              background-color: $primary-100;
            }
          }
        }
      }
    }

    .admin-my-box {
      @include flex(center, flex-end);
      flex-shrink: 0;
      flex-grow: 1;
      width: 36px;
      height: 70px;

      i {
        background: kyc-icon(-40px, 0);
        width: 37px;
        height: 37px;
        cursor: pointer;
        border-radius: 50%;
      }
    }
  }
}

@include desktop {
  #kyc-page-header {
    padding: 0 40px;
  }
}
