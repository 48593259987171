@import 'common/scss/utils.scss';

#admin-manage-theme-logo {
  @include flex(initial, initial);
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  h4 {
    color: $surface-high;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .theme-logo-options {
    padding-left: 16px;
  }

  .ubt__radio__group {
    .ubt__radio__item {
      &.custom {
        @include flex(initial, initial);
        flex-direction: column;
        gap: 8px;

        .logo-input-container {
          @include flex(initial, initial);
          gap: 8px;

          .input {
            &.logo {
              @include flex(initial, initial);
              gap: 8px;

              .image {
                @include flex(center, center);
                border-radius: 4px;
                border: 1px solid $gray-200;
                padding: 0 12px;
                background-color: $surface-100;
                height: 36px;
              }
            }

            input {
              visibility: hidden;
              display: none;
            }

            label {
              @include flex(center, center);
              font-size: 14px;
              line-height: 16px;
              cursor: pointer;
              padding: 0px 12px;
              color: $button-gray;
              background-color: $gray-100;
              border-radius: 8px;
              height: 36px;

              &.required {
                font-weight: 500;
                background-color: $surface-100;
                width: 112px;
                height: 36px;
                border-radius: 8px;
                border: 1px solid $surface-disabled;
              }
            }
          }

          .description {
            @include flex(initial, center);
            flex-direction: column;

            p {
              @include flex(center, initial);
              color: $surface-medium;
              font-size: 12px;
              line-height: 14px;
              font-weight: 400;

              &::before {
                content: '';
                display: block;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: $surface-disabled;
                margin-right: 8px;
              }
            }
          }

          & img {
            max-width: 100px;
            max-height: 20px;
          }
        }
      }
    }
  }
}

.logo__image__error {
  @include flex(center, center);
  flex-direction: column;
  padding: 40px;

  h2 {
    color: $surface-high;
    line-height: 36px;
    margin-bottom: 24px;
  }

  p:last-of-type {
    margin-bottom: 32px;
  }

  button {
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    width: 420px;
    height: 60px;
    padding: 15px 16px;
    border-radius: 8px;
    background-color: $primary-100;
    color: $surface-100;
  }
}
