@import 'common/scss/utils.scss';

@mixin h4 {
  font-weight: 700;
  color: $surface-high;
}

#admin-manage-edd-ra-content {
  margin-top: 24px;
  position: relative;
  overflow-x: hidden;
  min-height: 50vh;

  & > *:not(.risk-detail-container) {
    position: relative;
    left: 0;
    transition: left 0.2s ease;

    &.detail {
      left: -100%;
    }
  }

  & > .header {
    @include flex(center, space-between);
    margin-bottom: 16px;

    .ubt__button {
      width: 51.48px;
      height: 38px;
      .ubt__button__context {
        letter-spacing: -0.14px;
        font-size: inherit;
        font-weight: inherit;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: $surface-medium;
    }

    .error-message {
      display: inline-flex;
      justify-content: flex-end;
      flex: 1;
      padding-right: 24px;
      color: $error-100;

      .message {
        font-size: 12px;
        font-weight: 400;
        padding: 8px;
        background-color: $gray-100;
        border-radius: 8px;
      }
    }

    .button-group {
      @include flex(center, flex-end);
      gap: 8px;

      button {
        padding: 8px 12px;
        border: 1px solid $gray-200;
        border-radius: 8px;

        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: $surface-100;

        color: $surface-medium;

        &:last-child {
          color: $primary-80;
        }

        p {
          margin-left: auto;
          margin-right: 96px;
          font-weight: 400;
        }

        &[disabled] {
          color: $surface-disabled;
        }
      }
    }
  }
}
