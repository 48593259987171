@import 'common/scss/utils.scss';

.head-year-module {
  padding: 16px;

  .head-year-area {
    @include flex(center, space-between);
    margin-bottom: 16px;
    padding: 4px 0;

    & > div {
      @include flex(center, initial);
      cursor: pointer;
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;

      &.next {
        div {
          transform: rotate(-180deg);
        }
      }

      &.prev,
      &.next {
        .disabled {
          cursor: default;
          color: #cccccc;
        }
      }
    }
  }
}
