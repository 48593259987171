@import 'common/scss/utils.scss';

#review-modal-reject-review {
  overflow: visible;
  .modal__content__form {
    overflow: visible;

    .modal__content {
      .ubt__select {
        .ubt__select__summary {
          .ubt__select__summary__title {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .input-field {
        @include flex(initial, initial);
        flex-direction: column;
        margin-top: -26px;
        p {
          color: $surface-disabled;
          align-self: flex-end;
          font-size: 14px;
          margin-bottom: 6px;
        }
      }
    }
  }

  .modal__footer {
    padding-top: 0;
  }
}
