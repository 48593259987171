@import 'common/scss/utils.scss';

#date-filter {
  & > .body {
    @include flex(initial, space-between);
    border-bottom: 1px solid #e4e6e7;
    .tab {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 4px;
      border-right: 1px solid #e4e6e7;
      height: auto;
      padding: 16px 0;
      & > li {
        padding: 8px 16px;
        width: 100px;
        height: 40px;
        line-height: 27px;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: #f6f6f6;
        }
        &.active {
          background-color: #f6f6f6;
          color: #2d2bd4;
          font-weight: 600;
        }
      }
    }
  }
}

body {
  .react-datepicker {
    border: 0;
    font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .react-datepicker__header {
      background-color: transparent;
      border-bottom: 0;
      .react-datepicker__current-month {
        margin-bottom: 16px;
        font-size: 14px;
      }
    }
    .react-datepicker__month {
      .react-datepicker__month-wrapper {
        .react-datepicker__month-text {
          width: 108px;
          height: 32px;
          line-height: 33px;
          text-align: center;
          border-radius: 0;
          &.react-datepicker__month-text--keyboard-selected {
            background-color: #2d2bd4;
            color: white;
          }
          &.react-datepicker__month-text--today {
            background-color: white;
            color: #2d2bd4;
          }
        }
      }
      .react-datepicker__week {
        & > div {
          border-radius: 0 !important;
        }
        & > .react-datepicker__day--today {
          background-color: transparent;
          color: #2d2bd4;
          font-weight: 700;
        }
        .react-datepicker__day--in-range {
          background-color: #f6f6f6;
          color: #363636;
        }
        .react-datepicker__day--in-selecting-range {
          background-color: #f6f6f6;
          color: #363636;
        }
        .react-datepicker__day--selected {
          color: #ffffff;
          background-color: #2d2bd4;
        }
        .react-datepicker__day--outside-month {
          color: #cccccc;
        }
        .react-datepicker__day--range-start {
          background-color: #2d2bd4;
        }
        .react-datepicker__day--range-end {
          background-color: #2d2bd4;
          color: #ffffff;
        }
      }
    }
  }
}
