.jack__button {
  color: #f5f5f5;
  font-weight: 500;
  border-radius: 8px;
}

.jack__button.register {
  @include flex(center, center);
  width: 62px;
  height: 32px;
  background-color: $primary-80;
  color: white;
  margin-left: 10px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}

.ubt__button {
  box-shadow: none;
  width: 100px;
  padding: 0;
  border-radius: 8px;

  .ubt__button__context {
    font-weight: bold;
    font-size: 14px;
  }
}
