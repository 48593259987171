@import 'common/scss/utils.scss';
@import '../../../SettingCard.scss';

#admin-manage-compare-log-content {
  margin-top: 24px;

  .admin-manage-face-compare-log {
    @include flex(initial, initial);
    flex-direction: column;

    .setting-area {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 8px;

      .navigate-area {
        padding: 16px 12px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: $surface-100;

        .ubt__button {
          justify-content: flex-start;
          height: 40px;
          background-color: transparent;
          color: $surface-high;

          .ubt__button__context {
            gap: 8px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
    }

    .ubt__table {
      &.face-compare-log-table {
        border: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .ubt__table__wrapper {
          font-size: 16px;
          line-height: 24px;

          .ubt__table__header__wrapper {
            .ubt__table__head {
              border-bottom-color: $gray-200;
              border-top: 1px solid $gray-200;
            }
          }

          .ubt__table__body__wrapper {
            min-height: 392px;

            .ubt__table__container {
              .ubt__table__body {
                min-height: 392px;

                .ubt__table__row {
                  .ubt__button {
                    &.expand {
                      display: inline-block;
                      width: auto;
                      background-color: transparent;
                    }
                  }
                }

                #ubt__no__data {
                  .content__wrapper {
                    .ubt__no__data__text {
                      &::before {
                        content: '변경 로그가 없습니다.';
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
