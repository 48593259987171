@import 'common/scss/utils.scss';

section#ra-setting-content {
  @include flex(initial, initial);
  flex-direction: column;

  & > article {
    padding: 24px 16px;
    background-color: $surface-100;
    // box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1), 0px 2px 8px 4px rgba(95, 101, 255, 0.05);
    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-200;
    }

    &:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.error {
      background-color: $error-10;
    }

    &.disabled {
      background-color: $gray-100;

      .title {
        color: $surface-medium;
      }
    }
  }
}
