@import 'common/scss/utils.scss';

.jack__pagination {
  height: 32px;
  margin: 24px 0;

  .jack__pagination__buttons {
    gap: 8px;
    padding: 0;

    .jack__left__arrow {
      width: 24px;
      i {
        width: 24px;
        height: 24px;
        background: kyc-icon(-20px, -130px);
        transform: rotate(180deg);
      }
    }
    .jack__left__arrow__start {
      width: 24px;
      i {
        width: 24px;
        height: 24px;
        background: kyc-icon(-40px, -130px);
        transform: rotate(180deg);
      }
    }
    .jack__right__arrow {
      width: 24px;
      i {
        width: 24px;
        height: 24px;
        background: kyc-icon(-20px, -130px);
      }
    }
    .jack__right__arrow__end {
      width: 24px;
      i {
        width: 24px;
        height: 24px;
        background: kyc-icon(-40px, -130px);
      }
    }

    .jack__page__items {
      margin: 0;

      .pagination-item {
        width: 32px;
        height: 32px;
        font-size: 16px;
        font-weight: 500;
        color: $gray-500;
        margin: 0;
        padding: 4px 0;

        &.active {
          color: $surface-high;
        }
      }
    }
  }
}
