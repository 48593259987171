@import 'common/scss/utils.scss';

#identification__dialog__wrapper {
  .ubt__dialog__content {
    @include flex(center, initial);
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .main {
      text-align: center;

      p {
        color: $surface-high;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .sub {
      @include flex(center, center);
      gap: 16px;
      border-radius: 8px;
      border: 1px solid $gray-300;
      width: 100%;
      padding: 16px;

      color: $surface-high;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      .accent {
        color: $primary-100;
        font-weight: 500;
      }
    }
  }
}
