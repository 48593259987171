@import 'common/scss/utils.scss';

.idcard-setting-wrap {
  .flex-box {
    @include flex(center, space-between);
    .button-group {
      @include flex(center, flex-end);
      margin-left: auto;
      gap: 8px;

      button {
        padding: 8px 12px;
        border: 1px solid $gray-200;
        border-radius: 8px;

        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: $surface-100;

        color: $surface-medium;

        &:last-child {
          color: $primary-80;
        }

        p {
          margin-left: auto;
          margin-right: 96px;
          font-weight: 400;
        }

        &[disabled] {
          color: $surface-disabled;
        }
      }
    }
  }
}
