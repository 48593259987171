@import 'common/scss/utils.scss';

#reviewer-review-table-expand-content-face-check {
  @include flex(initial, space-between);
  width: 100%;
  padding: 24px 10px 24px 56px;

  ul.info-section {
    li {
      @include flex(center, initial);
      height: 40px;

      &.sub-title {
        color: $primary-80;
        font-size: 14px;
        font-weight: 500;
      }

      h4 {
        @include flex(center, initial);
        width: 150px;
        font-size: 16px;
        font-weight: 500;
        margin-right: 4px;

        i {
          @include flex(center, center);
          width: 20px;
          height: 20px;
          &::before {
            content: '';
            display: block;
            background: url('/assets/img/kyc_icons.svg') no-repeat -171px -320px;
            width: 11px;
            height: 11px;
          }
        }
      }

      & > p {
        font-size: 16px;
        &.warning {
          color: $error-100;
        }
      }
    }
  }

  .image-section {
    @include flex(initial, initial);
    width: 41%;
    flex-direction: column;
    gap: 10px;

    .image-field {
      @include flex(center, initial);
      flex-direction: column;
      gap: 10px;

      h4 {
        color: $primary-80;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        text-align: left;
        margin-top: 10px;
      }

      img,
      div {
        width: 120px;
        height: 120px;
      }
      .image-expired {
        background: url('/assets/img/kyc_icons.svg') no-repeat -740px -206px;
      }
    }
  }
}
