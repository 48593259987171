@import 'common/scss/utils.scss';

#admin-user-modify-provider {
  .modal__content {
    height: 592px;

    .duplicate-message {
      font-size: 12px;
      color: #f53c3c;
    }

    .kyc__useb__possible {
      color: blue;
      font-size: 12px;
    }

    .email-check {
      margin-top: 8px;
      margin-bottom: 20px;
      gap: 10px;

      .jack__input__container {
        width: calc(100% - 94px);
      }

      .jack__button {
        width: 84px;
        height: 56px;
        flex-shrink: 0;
        background-color: $surface-medium;
      }

      .api-check-message {
        font-size: 12px;
        margin-top: 4px;
        color: $primary-100;
        &.error {
          color: $error-100;
        }
      }
    }
  }
}
