@import 'common/scss/utils.scss';
@import '../../../SettingCard.scss';

#admin-manage-theme-color {
  @include flex(initial, initial);
  flex-direction: column;
  gap: 8px;

  h4 {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }

  .theme-palette {
    @include flex(initial, initial);
    flex-direction: column;
    gap: 8px;
    padding-left: 16px;

    h4 {
      position: relative;
      width: 85px;
      color: $surface-medium;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
    }

    .palette-item {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 8px;

      h4 {
        @include flex(center, initial);
        gap: 6px;
        width: 100%;
      }
    }
  }

  .info-icon {
    @include flex(center, center);
    width: 23px;
    height: 23px;
    background: kyc-icon(-192px, -313px);
    cursor: pointer;
  }

  .ubt__radio__group {
    .ubt__radio__item {
      &.custom {
        flex-direction: column;
      }
    }
  }
}
