@import 'common/scss/utils.scss';

#duplicate-login-modal {
  gap: 16px;

  .ubt__dialog__summary {
    @include flex(center, initial);
    flex-direction: column;
    gap: 32px;
    height: 100%;

    i {
      width: 72px;
      height: 72px;
      background: kyc-icon(-656px, -88px);
    }
  }

  .ubt__dialog__content {
    @include flex(initial, initial);
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;

    .content {
      @include flex(center, center);
      flex-direction: column;
      color: $surface-high;

      p {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
      }
    }
  }

  .ubt__dialog__actions {
    .ubt__button {
      &.submit {
        flex: 1;
      }
    }
  }
}
