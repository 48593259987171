@import 'common/scss/utils.scss';

#subscribe-management-setting-driver {
  position: relative;
  width: 100%;

  .setting-driver-box {
    @include flex(initial, space-between);
    font-size: 1rem;
    line-height: 1.5rem;
    color: $surface-medium;

    &.active {
      color: $surface-high;
    }

    .title {
      & > h4 {
        font-weight: 500;
        font-size: 16px;
        margin-right: 6px;
      }
      & > p {
        font-weight: 400;
        font-size: 14px;
        color: $surface-medium;
      }
    }

    i.title-info {
      background: url('/assets/img/kyc_icons.svg') no-repeat -196px -318px;
      width: 16px;
      height: 16px;
      margin-top: 4px;
      cursor: pointer;
      position: relative;
    }
  }
  .judge-type-box {
    margin-top: 16px;
    @include flex(center, initial);
    gap: 45px;
    height: 52px;
    padding: 8px 16px;
    border: 1px solid $gray-200;
    border-radius: 8px;
    & > div {
      font-size: 14px;
      color: $surface-high;
      font-weight: 500;
    }
    & > p {
      font-size: 13px;
      color: $surface-medium;
    }
  }

  .content {
    flex: 1 0;
  }
  .button-group {
    @include flex(initial, space-between);
    gap: 8px;

    .edit {
      visibility: visible;
      color: $primary-100;
      &.active {
        visibility: hidden;
      }
    }

    .jack__button {
      width: 50px;
      height: 36px;
      border: 1px solid $gray-200;
      background-color: $surface-100;

      &.submit {
        & > div {
          color: $primary-100;
        }
      }
    }
  }
}

#admin-subscribe-driver-popover-content.description {
  background: url('/assets/img/kyc_icons.svg') no-repeat -384px -546px;
  width: 316px;
  height: 73px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-35%);
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 18px 27px 18.5px 32px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
