@import 'common/scss/utils.scss';

#auto-screening {
  position: relative;
  width: 100%;
  .setting-manual-box {
    @include flex(center, space-between);
    font-size: 1rem;
    line-height: 1.5rem;
    color: $surface-medium;

    &.active {
      color: $surface-high;
    }

    .title {
      & > h4 {
        font-weight: 500;
        font-size: 16px;
        margin-right: 6px;
      }
      & > p {
        font-weight: 400;
        font-size: 14px;
        color: $surface-medium;
      }
    }
  }
}
