@import 'common/scss/utils.scss';

#kyb__icons__page__main {
  padding: 50px;
  #kyb__icons__page__grid {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: max-content;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    text-align: center;

    .kyb__icons__item {
      &.active {
        .kyb__icons__item__frame {
          border: 2px solid #e91e63;
          background-color: #fff1f6;
        }

        .kyb__icons__item__name {
          color: #e91e63;
        }
      }

      .kyb__icons__item__frame {
        position: relative;
        min-height: 64px;
        background: #fff;
        border-radius: 6px;
        // box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
        border: 2px solid transparent;
        overflow: hidden;

        & > div {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .kyb__icons__item__name {
        margin-top: 0.5rem;
      }
    }
  }
}

// #kyb__copy__icons__toast {
//   width: 300px;
//   border-radius: 4px;
//   bottom: 20px;
//   left: 50%;
//   transform: translateX(-50%);

//   .ubt__toast__list {
//     .ubt__toast__text {
//       height: 45px;
//       border-left: 3px solid rgb(110, 192, 95);
//       background-color: #fff;
//       box-shadow:
//         0 2px 3px -1px rgba(0, 0, 0, 0.1),
//         0 2px 3px 0 rgba(71, 51, 51, 0.1),
//         0 1px 10px 0 rgba(0, 0, 0, 0.1);
//       svg {
//         color: #000;
//       }
//     }

//     span {
//       color: #000;
//     }
//   }
// }
