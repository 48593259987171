$gray-100: #f7f7f7;
$gray-200: #e4e6e7;
// $gray-400: #cccccc;
$gray-300: #ced1d2;
$gray-500: #abacad;

$button-gray: #6d6d6d;

$primary-100: #373eff;
$primary-80: #5f65ff;
$primary-60: #878bff;
$primary-40: #afb2ff;
$primary-20: #d7d8ff;
$primary-10: #ebecff;
$primary-5: #f9f9ff;

$secondary-100: #353648;

$error-100: #f53c3c;
$error-80: #f76363;
$error-60: #f98a8a;
$error-30: #fcc5c5;
$error-10: #feecec;

$success-100: #12b78f;
$success-80: #41c5a5;
$success-60: #71d4bc;
$success-30: #b8e9dd;
$success-10: #e7f8f4;

$background-100: #f3f5f7;

$surface-100: #ffffff;
$surface-disabled: #cccccc;
$surface-high: #363636;
$surface-medium: #868686;

$active-100: #191919;

@mixin flex($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin border($color) {
  border: 1px solid $color;
  border-radius: 8px;
}

@mixin tooltipArrow {
  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    display: block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #ffffff;
    filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.13));
    rotate: 270deg;
  }
}

@function kyc-icon($x, $y) {
  @return url('/assets/img/kyc_icons.svg') no-repeat $x $y;
}

@function Banks($x, $y) {
  @return url('/assets/img/kyc_banks.svg') no-repeat $x $y;
}

@function Big($x, $y) {
  @return url('/assets/img/kyc_banks.svg') no-repeat $x $y;
}

$breakpoint-desktop: 1024px;
@mixin desktop {
  @media (max-width: $breakpoint-desktop) {
    @content;
  }
}

// 변수를 사용하지 않는 컬러 값들
// #f5f5f5
// #191919

// red, blue
