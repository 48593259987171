@import 'common/scss/utils.scss';

#account-setting-subcode {
  .description {
    @include flex(initial, initial);
    gap: 24px;

    .preview-box {
      flex: 1;
      @include flex(initial, center);
      &.right {
        height: 200px;
      }
      .preview {
        width: 204px;
        height: 148px;
        background: kyc-icon(-1021px, 0px);
        background-repeat: no-repeat;
        position: relative;
        padding-top: 150px;

        & span:not(.info) {
          font-size: 14px;
          font-weight: 500;
          position: absolute;
          background-color: $surface-100;
          opacity: 0;
          width: 67px;
          top: 91px;
          user-select: none;
          padding-left: 3px;
          white-space: nowrap;
          overflow: hidden;
        }
        .info {
          @include flex(center, center);
          font-size: 12px;
          font-weight: 400;
          white-space: nowrap;
          color: $surface-medium;
          text-align: center;
        }

        &.left {
          background: kyc-icon(-1226px, 0px);
          & span {
            opacity: 1;
            width: 30px;
            left: 27px;
          }
        }
        &.right {
          background: url('/assets/img/kyc_icons.svg') no-repeat -1021px -150px;
          & span:not(.info) {
            opacity: 1;
            left: 63px;
          }
        }
      }
    }

    .edit-option {
      flex: 1;

      .ubt__radio__group {
        .ubt__radio__item {
          padding: 16px;
          border: 1px solid $gray-200;
          border-radius: 8px;

          &.checked {
            border-color: $primary-100;
          }
        }
      }
    }

    .display-option {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 8px;
      flex: 1;
      padding: 0;
      border: none;
      border-radius: 0;

      .display-wrapper {
        @include flex(center, initial);
        padding: 16px;
        border: 1px solid $gray-200;
        border-radius: 8px;

        .display-container {
          @include flex(center, flex-end);
          flex: 1;
          font-size: 14px;
          line-height: 24px;

          .certification {
            @include flex(center, initial);
            gap: 8px;

            &.right {
              flex-direction: row-reverse;
            }
            & > .value {
              border: 1px solid $gray-200;
              border-radius: 8px;
              height: 40px;
              @include flex(center, center);
              padding: 8px 16px;
            }
          }
        }
      }

      .subcode-right-info-text {
        color: $surface-medium;
        font-size: 13px;
        line-height: 20px;
      }
    }

    .input-area {
      position: relative;
      @include flex(center, flex-end);
      gap: 8px;
      flex: 1;
      font-size: 14px;
      line-height: 24px;
      color: $surface-high;

      .jack__input__container {
        width: 97px;
        position: static;
        input {
          color: $surface-high;
          &::placeholder {
            color: $surface-disabled;
          }
        }
        .jack__validator__error__message {
          position: absolute !important;
          top: 32% !important;
          right: 180px !important;
          transform: translateY(-50%);
        }
      }
    }
  }
}
