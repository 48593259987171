@import 'common/scss/utils.scss';

#reviewer-result-table-expand-content-account {
  @include flex(initial, initial);
  width: 100%;
  padding: 24px 0px;

  ul.info-section {
    width: 50%;

    li {
      &.sub-title {
        color: $primary-80;
        font-size: 14px;
        font-weight: 500;
      }
    }

    li:not(.id-info) {
      @include flex(center, initial);
      height: 40px;
      h4 {
        width: 150px;
        font-size: 16px;
        font-weight: 500;
      }

      & > p {
        font-size: 16px;
        &.warning {
          color: $error-100;
        }
      }
      & .id-expired {
        color: $surface-medium;
        font-size: 12px;
        display: inline-block;
      }
    }
  }
}
