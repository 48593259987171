@import 'common/scss/utils.scss';

body {
  .ubt__button {
    font-family: inherit;
    line-height: normal;
    box-shadow: none;
    background-color: #5f65ff;
    border-radius: 8px;
    font-size: 14px;
    height: 32px;

    &:disabled {
      color: #c5c6ca;
      background-color: #868686;
      cursor: auto;
      opacity: 0.5;
      &:hover {
        background-color: #868686;
      }
    }
    .ubt__button__context {
      @include flex(center, space-between);
    }
  }
}
