@import 'common/scss/utils.scss';

.ubt__checkbox {
  .ubt__label.ubt__checkbox__label {
    .ubt__checkbox__container {
      color: red;
      &:has(input[type='checkbox']:checked) {
        background-color: #373eff;
        border: 1px solid #373eff;
      }
    }
  }
}
