@import 'common/scss/utils.scss';
@import '../../../../SettingCard.scss';

#idcard-setting-wasm-ocr {
  position: relative;
  width: 100%;

  @include display-option {
    @include flex(initial, initial);
    flex-direction: column;
    padding: 0;
    border: none;

    .judge-type-box {
      @include flex(center, space-between);

      height: 52px;
      padding: 8px 16px;
      border: 1px solid $gray-200;
      border-radius: 8px;
      .flex-box {
        @include flex(center, initial);
        gap: 16px;

        & > div {
          font-size: 14px;
          color: $surface-high;
          font-weight: 500;
          width: 180px;
        }
        & > p {
          font-size: 13px;
          color: $surface-medium;
          font-weight: 400;
        }
      }
      .use-text {
        width: 71px;
        @include flex(center, center);
        font-size: 14px;
        font-weight: 500;
        background-color: #f6f6f6;
        height: 34px;
        line-height: 34px;
        color: $surface-disabled;
        border-radius: 8px;
        background-color: #f6f6f6;

        &.active {
          color: $primary-80;
          background-color: transparent;
        }
      }
    }
  }
}
