@import 'common/scss/utils.scss';

#admin-reviewer-modal-modify-my {
  .content {
    p:not(.kyc__error__label, .duplicate-message, .kyc__useb__possible) {
      text-align: center;
      font-size: 14px;
      line-height: 22px;

      span {
        color: $primary-80;
      }
    }
  }
}
