@import 'common/scss/utils.scss';

#admin-manage-edd-weight-content {
  margin-top: 24px;

  .header {
    @include flex(center, space-between);
    margin-bottom: 16px;

    h3 {
      @include flex(center, space-between);
      gap: 8px;
      font-size: 18px;
      font-weight: 500;
      color: $surface-medium;

      .tooltip {
        @include flex(center, center);
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        color: $surface-medium;
      }
      i.tooltip-info {
        background: kyc-icon(-196px, -318px);
        width: 16px;
        height: 16px;
        cursor: pointer;
        position: relative;
      }
    }

    .button-group {
      @include flex(center, flex-end);
      gap: 8px;

      button {
        padding: 8px 12px;
        @include border($gray-200);
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: $surface-100;
        color: $surface-medium;

        &:last-child {
          color: $primary-80;
        }

        p {
          margin-left: auto;
          margin-right: 96px;
          font-weight: 400;
        }

        &[disabled] {
          color: $surface-disabled;
        }
      }
    }
  }

  .weight-content {
    background-color: $surface-100;
    border-radius: 8px;
  }
}
