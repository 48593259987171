@import 'common/scss/utils.scss';

.edd-setting-ra-weight {
  .extra {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 32px;
    padding: 16px 24px;
    font-size: 14px;
    font-weight: 500;
    color: $surface-high;
    border-bottom: 1px solid $gray-200;

    span {
      @include flex(center, flex-start);
      gap: 8px;
    }
    span:last-child {
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      color: $primary-100;
    }

    i.tooltip-info {
      background: kyc-icon(-196px, -318px);
      width: 16px;
      height: 16px;
      cursor: pointer;
      position: relative;
    }
  }
  section {
    @include flex(initial, initial);
    flex-direction: column;

    .footer {
      @include flex(center, flex-end);
      gap: 8px;
      padding: 16px 24px;

      div {
        display: inline-flex;
        gap: 24px;
        height: 44px;
      }

      span {
        @include flex(center, initial);
        gap: 36px;
        font-weight: 500;
        font-size: 14px;

        &:last-child {
          font-size: 16px;
          color: $primary-100;
        }
      }

      .error__message {
        font-size: 12px;
        padding: 8px;
        background-color: $gray-100;
        border-radius: 8px;
        height: auto;
      }
    }
  }
  article {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;
    padding: 16px 24px;
    border-bottom: 1px solid $gray-200;

    .title {
      flex-basis: 130px;
      font-size: 16px;
      font-weight: 700;
    }
    .weight {
      @include flex(initial, center);
      justify-self: center;
      width: 128px;
      padding: 4px;
      background-color: $surface-100;
      border: 1px solid $gray-300;
      border-radius: 8px;

      button {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        backdrop-filter: contrast(0.9);

        &.disabled {
          opacity: 0.3;
        }
        &:first-child {
          background: kyc-icon(-44px, -218px);
        }
        &:last-child {
          background: kyc-icon(-69px, -218px);
        }
      }
      .jack__input__container {
        input {
          border: none;
          transition: none;
          appearance: textfield;
          color: $primary-100;
          font-size: 16px;
          font-weight: 500;
          padding-left: 0px;
          text-align: center;

          &::-webkit-inner-spin-button,
          &::-webkit-inner-spin-button {
            appearance: none;
          }
        }
      }
    }
    .score {
      @include flex(center, flex-end);
      gap: 36px;

      span:first-child {
        font-size: 14px;
        font-weight: 400;
        color: $surface-medium;
      }
      span:last-child {
        font-weight: 500;
        color: $primary-80;
      }
      div {
        @include flex(center, space-between);
        gap: 24px;

        span:last-child {
          min-width: 42px;
          text-align: right;
        }
      }
    }
  }

  .hide {
    visibility: hidden;
  }
  .error {
    color: $error-100 !important;
  }
}

#admin-edd-setting-ra-weight-popover-content.description {
  position: absolute;
  transform: translate(8%, -105%);
  width: 320px;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 8px 16px;
  background-color: $surface-100;
  border-radius: 8px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.1);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include tooltipArrow;
}

#admin-edd-setting-ra-extra-weight-popover-content.description {
  background: kyc-icon(-388px, -538px);
  width: 306px;
  position: absolute;
  transform: translate(7%, -26%);
  font-size: 0.625rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 24px 8px 16px 20px;
  scale: 1.2;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
