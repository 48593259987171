@import 'common/scss/utils.scss';

.kyc-footer {
  @include flex(initial, center);
  height: 32px;
  margin: 24px 0;
  background-color: $primary-5;

  .footer-content {
    @include flex(center, center);
    width: 400px;
    margin-bottom: 24px;

    @mixin vertical-line {
      @include flex(center, center);
      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 10px;
        margin: 0 10px;
        background-color: $gray-300;
      }
    }

    .terms {
      @include vertical-line;
      font-size: 12px;
      color: $surface-medium;
      cursor: pointer;
    }

    .policy {
      @include vertical-line;
      font-size: 12px;
      cursor: pointer;
    }

    .contact {
      @include flex(initial, initial);
      gap: 8px;

      p {
        font-size: 12px;
        color: $surface-medium;
      }
    }
  }
}
