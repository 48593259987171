@import 'common/scss/utils.scss';

.form__multitoggle__element {
  height: 56px;
  width: 100%;
  .ubt__multitoggle {
    height: 100%;
    width: 100%;
    .ubt__multitoggle__container {
      height: 100%;
      width: 100%;
      grid-auto-rows: auto !important;
      border-radius: 8px;

      .ubt__multitoggle__item {
        height: 100%;
        font-size: 16px;
        border-color: #e4e6e7;
        color: #868686;
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &.selected {
          border: 2px solid #5f65ff !important;
          background-color: transparent;
          color: #363636;
          font-weight: 500;
        }
      }
    }
  }
}
