@import 'common/scss/utils.scss';

#ssa-edit-option.edit-option {
  .ubt__radio__group {
    &.ssa-radio-group {
      flex-direction: initial;

      .ubt__radio__item {
        flex-direction: column;
        gap: 4px;
        border: 1px solid $gray-200;
        border-radius: 8px;
        padding: 8px 8px 8px 16px;

        &.checked {
          border-color: $primary-100;
        }

        .ubt__radio__box + p {
          color: $surface-medium;
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}
