@import 'common/scss/utils.scss';

.ubt__switch {
  height: 16px;
  width: 32px;

  .ubt__switch__slider {
    background-color: #e6e6e6;

    &:before {
      height: 12px;
      width: 12px;
    }

    &:is(input:checked + .ubt__switch__slider) {
      background-color: #373eff;

      &:before {
        transform: translateX(16px);
      }
    }
  }
}
