@import 'common/scss/utils.scss';

@mixin h4 {
  font-weight: 700;
  color: $surface-high;
}

.edd-ra-config-controller {
  @include flex(initial, initial);
  flex-direction: column;
  margin-bottom: 16px;
  background-color: $surface-100;
  border-radius: 8px;

  article {
    flex: 1;
    padding: 16px 24px;
    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-200;
    }
    & > div {
      @include flex(center, initial);
      gap: 16px;
      background-color: $surface-100;
      border-radius: 8px;
      @include h4;
    }

    &:last-of-type {
      background-color: transparent;
      & > div {
        background-color: transparent;
        border: none;
      }
    }

    &.disabled {
      background-color: $gray-100;
      h4 {
        color: $surface-medium;
      }
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $surface-medium;
    }
    .content {
      @include flex(initial, flex-end);
      flex: 1;

      .disabled {
        opacity: 0.6;
        cursor: default;
        .switch__button {
          cursor: default;
        }
      }
    }

    .jack__select {
      width: 192px;
      height: 48px;
      .jack__select__button {
        font-weight: 400;
        padding: 0 16px;
        .jack__select__arrow__icon {
          z-index: 101;
        }
      }
      .jack__select__options {
        top: 0px !important;
        border: 1px solid $primary-80 !important;
        border-radius: 8px !important;
      }
    }
  }
}
