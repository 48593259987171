@import 'common/scss/utils.scss';

.react-datepicker__current-month {
  .react-datepicker__navigation {
    .react-datepicker__navigation-icon--previous {
      &::before {
        top: -1px;
      }
    }
    .react-datepicker__navigation-icon--next {
      &::before {
        top: -1px;
      }
    }
  }
}
