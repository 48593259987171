@import 'common/scss/utils.scss';

.half-year-content {
  .half-list {
    @include flex(center, initial);
    gap: 16px;
    flex-direction: column;
    margin-top: 8px;
    & > div {
      width: 240px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #f0f0f0;
      }
      &.active {
        background-color: #2d2bd4;
        color: white;
      }
    }
  }
}
