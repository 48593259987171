@import 'common/scss/utils.scss';

#promotion-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  @include flex(center, center);

  & > .promotion-text {
    color: $surface-high;
    background-color: $primary-5;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
  }
}
