@import 'common/scss/utils.scss';

#edit-wrap {
  @include flex(center, flex-end);
  .use-text {
    width: 71px;
    @include flex(center, center);
    font-size: 14px;
    font-weight: 500;
    background-color: #f6f6f6;
    height: 34px;
    line-height: 34px;
    color: $surface-disabled;
    border-radius: 8px;
    margin-right: 16px;
    &.active {
      color: $primary-80;
      background-color: transparent;
    }
  }
  .edit-btn-box {
    @include flex(center, initial);
    gap: 8px;
    .btn-edit {
      font-size: 14px;
      width: 50px;
      height: 36px;
      border: 1px solid $gray-200;
      background-color: $surface-100;
      color: $surface-high;

      &.ubt__button {
        box-shadow: none;
        padding: 0;
        .ubt__button__context {
          font-weight: 500;
        }
      }

      &.btn-submit {
        color: $primary-80;
      }
      &.btn-cancel {
        color: $surface-medium;
      }
    }
  }
  .switch-box {
    @include flex(center, center);
    gap: 8px;
    font-size: 14px;
    .switch-text {
      width: 55px;
      font-weight: 500;
      margin-right: 16px;
      color: $surface-disabled;
      &.active {
        color: $primary-80;
      }
    }
  }
}
