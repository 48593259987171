@import 'common/scss/utils.scss';
@import '../../SettingCard.scss';

#admin-management-security-content {
  .article-container {
    @include flex(initial, initial);
    flex-direction: column;
    gap: 8px;
  }
  #admin-manage-security-hmac {
    @include admin-manage-section {
      @include admin-manage-section-header;
      @include admin-manage-section-edit-control;

      @include description {
        @include display-option;
        @include edit-option {
          .ubt__radio__group {
            flex-direction: initial;

            .ubt__radio__item {
              flex-direction: column;
              gap: 4px;
              border: 1px solid $gray-200;
              border-radius: 8px;
              padding: 8px 8px 8px 16px;

              &.checked {
                border-color: $primary-100;
              }

              .ubt__radio__box + p {
                color: $surface-medium;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
