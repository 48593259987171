@import 'common/scss/utils.scss';
@import '../../../SettingCard.scss';

#admin-manage-liveness-setting-content {
  margin-top: 24px;

  .admin-manage-face-liveness-setting {
    @include flex(initial, initial);
    flex-direction: column;
    gap: 24px;

    .setting-area {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 8px;

      .setting-title {
        h4 {
          color: $surface-medium;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .navigate-area {
        padding: 16px 24px;
        border-radius: 8px;
        background-color: $surface-100;

        .ubt__button {
          width: 100%;
          background-color: transparent;
          color: $surface-high;

          .ubt__button__context {
            width: 100%;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.admin-manage-face-liveness-setting {
  section {
    @include admin-manage-section {
      @include admin-manage-section-header;
      @include admin-manage-section-edit-control;

      @include description {
        @include display-option;
        @include edit-option;
      }
    }
  }
}
