@import 'common/scss/utils.scss';

.ubt__dialog {
  .ubt__dialog__base {
    padding: 40px 0;
    gap: 32px;
    border-radius: 8px;

    .ubt__dialog__summary {
      text-align: left;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      padding: 0 40px;
    }
    .ubt__dialog__content {
      height: calc(100% - 32px);
      padding: 0 40px;
    }

    .ubt__dialog__actions {
      padding: 0 40px;
      @include flex(center, center);

      .ubt__button {
        font-size: 16px;
        width: 65%;
        height: 60px;
        background-color: #373eff;
        color: #ffffff;
        flex: 1;

        &.cancel {
          background-color: #f6f6f6;
          color: #868686;
          width: 35%;
          flex: none !important;
        }
        .ubt__button__context {
          font-size: 16px;
        }
      }
    }
  }
}
