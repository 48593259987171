@import 'common/scss/utils.scss';
#root-admin-controller-title {
  @include flex(center, initial);
  gap: 8px;
  & > p {
    flex: 1;
    width: 100%;
  }
  .ubt__select {
    height: 36px;
  }
}
