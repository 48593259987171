@import 'common/scss/utils.scss';

#client__dashboard__modal__wrapper {
  position: relative;

  .ubt__dialog__summary {
    .ubt__dialog__close__icons {
      right: 40px;
    }
  }

  .ubt__dialog__content {
    @include flex(initial, initial);
    flex-direction: column;
    gap: 16px;

    .table-control {
      @include flex(center, space-between);
      gap: 16px;

      .name {
        .customers-select {
          .ubt__select__summary {
            .ubt__select__summary__title {
              font-size: 16px;
              line-height: 24px;
              font-weight: 400;
              color: $surface-high;
            }
          }
        }
      }

      .control {
        @include flex(initial, initial);
        gap: 16px;
      }

      .select-area {
        height: 48px;
        min-width: 225px;
        font-size: 16px;
        line-height: 24px;

        & > div {
          @include flex(center, space-between);
          gap: 8px;
          border: 1px solid #cccccc;
          border-radius: 8px;
          padding: 8px 10px;
          height: 100%;
        }
      }

      .display-unit-select {
        height: 48px;
        width: 160px;
        .ubt__select__summary {
          .ubt__select__summary__title {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    .dashboard-all-section {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 16px;

      .report-info {
        @include flex(initial, initial);
        gap: 16px;

        .report-table {
          .grid-container {
            display: grid;
            grid-template-columns: 74px 147px 204px;
            height: 100%;
            border-top: 1px solid $surface-disabled;
            border-left: 1px solid $surface-disabled;
            border-radius: 8px;

            .title {
              background-color: $gray-100;
            }
            .unpaid {
              background-color: $gray-200;
            }

            .grid-cell {
              @include flex(center, center);
              padding: 8px 16px;
              border-right: 1px solid $surface-disabled;
              border-bottom: 1px solid $surface-disabled;
              font-size: 16px;
              line-height: 24px;
              color: $surface-high;
              text-wrap: nowrap;

              &.rowSpan {
                grid-row: 1 / 6;
              }

              &:nth-child(1) {
                border-top-left-radius: 8px;
              }
              &:nth-child(3) {
                border-top-right-radius: 8px;
              }
              &:nth-child(12) {
                border-bottom-left-radius: 8px;
              }
              &:nth-child(14) {
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }

      .report-chart {
        @include flex(center, center);
        gap: 16px;
        flex: 1;
        border: 1px solid $surface-disabled;
        border-radius: 8px;
        padding: 16px;
        height: 320px;
      }
    }

    .dashboard-daily-section {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 16px;

      .report-info {
        .grid-container {
          @include flex(initial, initial);
          flex-direction: column;
          font-size: 16px;
          line-height: 25px;
          font-weight: 400;
          position: relative;
          overflow: auto;

          .grid-cell {
            @include flex(center, center);
            padding: 8px 16px;
            border-right: 1px solid $surface-disabled;
            border-bottom: 1px solid $surface-disabled;
          }

          .grid-header {
            position: sticky;
            top: 0;
            z-index: 1;
            display: grid;
            grid-template-columns: 145px repeat(6, 1fr);
            background-color: $surface-100;
            // border-top-left-radius: 8px;
            // border-top-right-radius: 8px;
            // border-top: 1px solid $surface-disabled;
            // border-left: 1px solid $surface-disabled;

            .grid-cell {
              &.title {
                background-color: $gray-100;
              }
              &.unpaid {
                background-color: $gray-200;
              }

              &.rowSpan {
                grid-row: 1 / 3;
              }

              &.colSpan {
                grid-column: 2 / 7;
              }

              &:nth-child(1) {
                border-top: 1px solid $surface-disabled;
                border-left: 1px solid $surface-disabled;
                border-top-left-radius: 8px;
              }
              &:nth-child(2) {
                border-top: 1px solid $surface-disabled;
              }
              &:nth-child(3) {
                border-top: 1px solid $surface-disabled;
                border-top-right-radius: 8px;
              }
            }
          }

          .grid-body {
            max-height: 294px;

            .grid-row {
              display: grid;
              grid-template-columns: 145px repeat(6, 1fr);

              .grid-cell {
                &:first-of-type {
                  border-left: 1px solid $surface-disabled;
                }
              }

              &:last-of-type {
                border-bottom-left-radius: 8px;

                .grid-cell {
                  &:first-of-type {
                    border-bottom-left-radius: 8px;
                  }
                  &:last-of-type {
                    border-bottom-right-radius: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .total-count-info {
      @include flex(center, initial);
      gap: 16px;
      font-size: 16px;
      line-height: 24px;
      color: $surface-high;
      border: 1px solid $surface-disabled;
      border-radius: 8px;

      .total {
        @include flex(center, initial);
        padding: 16px;
        flex: 1;

        span:nth-child(2) {
          flex: 1;
          text-align: center;
          font-weight: 500;
        }
      }
    }
  }
}
