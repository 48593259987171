@import 'common/scss/utils.scss';

section.root-client-table-tr-expand {
  border-top: 3px solid $primary-5;
  padding: 26px 30px;
  height: 100%;

  .content-wrapper {
    border: 1px solid $gray-200;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;

    .content-header {
      @include flex(center, space-between);
      height: 64px;
      background-color: $primary-5;
      padding: 0 32px;

      .title-info {
        @include flex(center, initial);
        h4 {
          font-size: 16px;
          font-weight: 500;
        }

        p {
          @include flex(center, initial);
          margin-left: 32px;
          font-size: 14px;

          span:last-child {
            color: $surface-medium;
            margin-left: 4px;
          }
        }
      }

      .util-buttons {
        @include flex(initial, space-between);
        gap: 8px;

        .jack__button {
          width: 50px;
          height: 36px;
          border: 1px solid $gray-200;

          &.cancel {
            background-color: $primary-5;
            & > div {
              color: $surface-medium;
            }
          }

          &.submit {
            background-color: $surface-100;
            & > div {
              color: $primary-100;
            }
          }
        }

        .api-dashboard {
          width: auto;
        }
      }
    }

    .content-body {
      display: grid;
      grid-template-rows: repeat(3, minmax(10px, auto));
      row-gap: 16px;
      padding: 24px 32px;
    }
  }
  .content-footer {
    @include flex(initial, flex-end);
    gap: 10px;

    .jack__button {
      border: 1px solid $gray-200;
      padding: 8px 12px;
      &.cancel {
        background-color: $surface-100;
        & > div {
          color: $surface-medium;
        }
      }
    }
  }
}
