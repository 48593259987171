@import 'common/scss/utils.scss';

#admin-manage-ra-realscore-container {
  margin-top: 24px;
  h3 {
    font-size: 20px;
    font-weight: 500;
    color: $surface-medium;
  }

  .header {
    @include flex(center, space-between);
    margin-bottom: 16px;

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: $surface-medium;
    }

    .button-group {
      @include flex(center, flex-end);
      margin-left: auto;
      gap: 8px;

      button {
        padding: 8px 12px;
        border: 1px solid $gray-200;
        border-radius: 8px;

        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: $surface-100;

        color: $surface-medium;

        &:last-child {
          color: $primary-80;
        }

        p {
          margin-left: auto;
          margin-right: 96px;
          font-weight: 400;
        }

        &[disabled] {
          color: $surface-disabled;
        }
      }
    }
  }

  .control-pannel {
    @include flex(center, flex-end);
    margin-top: 8px;

    button {
      @include border($gray-300);
      background-color: $surface-100;
      padding: 8px 16px;
      color: $surface-medium;
      transition: all 0.3s ease;
      width: 105px;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid $primary-80;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
      }
      &.active {
        background-color: $surface-100;
        color: $surface-high;
        border-color: $primary-80;
      }
    }
  }
}
