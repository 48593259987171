@import 'common/scss/utils.scss';

#dialog-unlock-account {
  .ubt__dialog__summary {
    text-align: center;
  }
  .ubt__dialog__content {
    @include flex(initial, initial);
    flex-direction: column;
    gap: 24px;

    .description {
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      color: var(--surface-high);
    }
    .info-area {
      padding: 16px 24px;
      border: 1px solid #cccccc;
      border-radius: 8px;

      .title {
        @include flex(center, initial);
        gap: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 16px;

        & > p {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .list {
        @include flex(initial, initial);
        flex-direction: column;
        gap: 16px;

        & > dl {
          @include flex(center, initial);
          gap: 40px;
          font-size: 15px;
          line-height: 24px;
          dt {
            font-weight: 500;
            width: 60px;
          }

          dd {
            font-weight: 400;

            &.flex {
              @include flex(center, initial);
              font-weight: 700;
              gap: 8px;
              color: #868686;

              & > p {
                &.active {
                  color: $primary-100;
                }
              }
            }
          }
        }
      }
    }
  }
}
