@import 'common/scss/utils.scss';

#reviewer-result-table-expand-content-idcard {
  @include flex(initial, initial);
  width: 100%;
  padding: 24px 0px;

  ul.info-section {
    width: 50%;
    li {
      &.sub-title {
        color: $primary-80;
        font-size: 14px;
        font-weight: 500;
      }

      @include flex(center, initial);
      height: 40px;
      h4 {
        width: 150px;
        font-size: 16px;
        font-weight: 500;
      }

      & > p {
        font-size: 16px;
        .upload-type {
          color: $surface-medium;
          font-size: 12px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          background-color: $gray-100;
          border-radius: 8px;
          margin-left: 10px;
          padding: 4px 8px;
        }
        &.warning {
          color: $error-100;
        }
      }
      &.ssa-info {
        @include flex(initial, initial);
        margin-top: 8px;
        font-size: 16px;
        height: auto;
        .ssa-info-value {
          @include flex(initial, initial);
          gap: 4px;
          flex-direction: column;

          &.real {
            p {
              color: $success-100;
            }
          }
          &.fake {
            p {
              color: $error-100;
            }
          }

          & > .ssa-info-value-flex {
            @include flex(center, initial);
            gap: 4px;

            & > .icon-ex-mark {
              background: url('/assets/img/kyc_icons.svg') no-repeat -196px -318px;
              width: 16px;
              height: 16px;
            }
          }
        }

        & > div {
          p {
            & > .real {
              color: #12b78f;
            }
            & > .fake {
              color: #f53c3c;
            }
          }
        }
      }
    }
    li.idType-title.manual-input {
      height: 50px;
      @include flex(flex-start, initial);
      div {
        p:first-child {
          font-size: 1rem;
        }
        p:last-child {
          font-size: 0.875rem;
        }
      }
    }

    li.id-info {
      padding: 4px 0;
      display: block;
      height: auto;

      & > div {
        @include flex(center, initial);
        height: 24px;
        margin: 8px 0;
        &.manual-input {
          @include flex(flex-start, initial);
          height: 41px;
        }

        h5 {
          width: 150px;
          font-size: 16px;
          color: $surface-medium;
          font-weight: 400;
          &.manual-input {
            line-height: 1.5rem;
            div:last-child {
              margin-left: 10px;
              font-size: 0.875rem;
            }
          }
        }

        & > p {
          font-size: 16px;
          &.warning {
            color: $error-100;
          }
        }
      }
      & .id-expired {
        color: $surface-medium;
        padding-bottom: 8px;
        font-size: 12px;
        display: inline-block;
      }
    }
    .verify-failed {
      color: $error-100;
    }
  }

  .image-section {
    @include flex(center, initial);
    width: 38%;
    flex-direction: column;
    gap: 10px;
    .image-title {
      color: $primary-80;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      text-align: left;
      margin-top: 5px;
    }
    .id-crop-img {
      width: 120px;
      // height: 120px;
      & > img {
        width: 100%;
        border: 1px solid $gray-300;
      }
    }

    .id-crop-img-expired {
      position: relative;
      i {
        width: 120px;
        height: 120px;
        background: url('/assets/img/kyc_icons.svg') no-repeat -740px -205px;
      }
    }

    .image-expired {
      position: relative;
      i {
        width: 280px;
        height: 200px;
        background: url('/assets/img/kyc_icons.svg') no-repeat -740px 0px;
      }
    }
    .image-field {
      position: relative;
      cursor: pointer;
      img {
        width: 280px;
        height: 200px;
        border: 10px solid rgb(196, 196, 196);
      }

      i {
        width: 26px;
        height: 26px;
        background: url('/assets/img/kyc_icons.svg') no-repeat -257px -8px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .jack__button {
      background-color: $surface-100;
      border: 1px solid $gray-200;
      color: $surface-medium;
      height: 36px;
    }
  }
}

.reviewer-image-section-modal {
  position: relative;
  max-width: 795px;
  border-radius: 0;
  border: 25px solid rgb(196, 196, 196);
  line-height: 0;
  overflow: visible;

  .close-btn {
    position: absolute;
    right: -20px;
    top: -60px;
    width: 19px;
    height: 18px;
    background: url('/assets/img/kyc_icons.svg') no-repeat -45px -198px;
    cursor: pointer;
  }
}

.tooltip-ssa-example {
  .ssa-popover-white {
    background: url('/assets/img/kyc_icons.svg') no-repeat -384px -546px;
    width: 316px;
    height: 73px;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-35%);
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $surface-medium;
    padding: 18px 27px 18.5px 32px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
}
