@import 'common/scss/utils.scss';

.kyc__loading {
  @include flex(flex-start, flex-start);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 55;
  width: 100vw;
  height: 100vh;

  .kyc__loading__back {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(122, 122, 122, 0.3);
  }

  .loading__content__wrap {
    @include flex(center, center);
    width: 100%;
    height: 100%;
    position: absolute;

    .loading__content {
      @include flex(center, center);
      flex-direction: column;
      color: $surface-100;

      .loading__dots {
        padding: 30px 0;
      }

      p {
        margin-top: 8px;
      }

      & > p:nth-child(2) {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}
