@import 'common/scss/utils.scss';

.form__select__element {
  height: 56px;
  width: 100%;
  .ubt__select {
    height: 100%;
    .ubt__select__summary {
      .ubt__select__summary__title {
        font-size: 16px;
      }
    }
  }
}

.ubt__select {
  height: 56px;
  width: 100%;

  &.disabled {
    .ubt__select__summary {
      background-color: white;
      .ubt__select__summary__title {
        color: #cccccc;
      }
    }
  }
  .ubt__select__summary {
    height: 100%;
    min-width: 121px;
    border-color: #cccccc;
    border-radius: 8px;
    &.open {
      border: 1px solid #2d2bd4;
      .ubt__select__icon__arrow {
        & > svg {
          transform: rotateX(180deg);
        }
      }
    }
    .ubt__select__summary__title {
      color: #363636;
      font-size: 14px;
      font-weight: 400;
      span.ubt__select__placeholder {
        color: #868686;
        font-weight: 500;
      }
    }
  }
}

.ubt__popover__poper {
  .ubt__popover__poper__content {
    border-radius: 8px;
    border: 1px solid #2d2bd4;
    overflow: hidden;
    .ubt__select__options {
      .ubt__select__item {
        font-size: 16px;
        font-weight: 400;
        height: 56px;

        &:last-child {
          border-bottom: 0;
        }
        &:hover {
          background-color: #ebecff;
        }
        &.selected {
          color: #2d2bd4;
          font-weight: 500;
        }
      }
    }
  }
}
