@import "common/scss/utils.scss";

.range__slider__container {
  .slider {
    width: 100%;
    height: 36px;
    display: inline-block;
    position: relative;

    input[type="range"] {
      position: absolute;
      top: 0;
      width: 100%;
      height: 0;
      pointer-events: none;
      appearance: none;
      -webkit-appearance: none;
      z-index: 1;

      &.range__slider__lower::-webkit-slider-thumb {
        width: 16px;
        height: 16px;
        background: #f53c3c;
      }
      &.range__slider__upper::-webkit-slider-thumb {
        width: 16px;
        height: 16px;
        background: #12b78f;
      }

      &::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        pointer-events: all;
        cursor: pointer;
      }
      &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        pointer-events: all;
        cursor: pointer;
        transform: translateY(3px);
        border: 2px solid #ffffff;
        border-radius: 50%;
        box-shadow:
          0px 0px 2px rgba(0, 0, 0, 0.1),
          0px 1px 8px rgba(0, 0, 0, 0.1);
      }

      &:disabled::-webkit-slider-thumb {
        border: 0px;
        width: 14px;
        height: 14px;
        cursor: default;
      }
    }
    // gradient bar
    .slider__rail {
      height: 4px;
      border-radius: 0;
      position: relative;
      z-index: 0;

      .dots {
        @include flex(initial, space-between);
        position: absolute;
        width: calc(100% - 4px);
        width: 100%;
        transform: translateY(-2px);
        padding: 0 8px;

        i {
          width: 2px;
          height: 8px;
          background-color: #5755dd;

          &.lower {
            background-color: #f53c3c;
          }
          &.upper {
            background-color: #12b78f;
          }
          &.match {
            height: 0;
          }
        }
      }

      .counts {
        @include flex(initial, space-between);
        position: absolute;
        width: calc(100% - 4px);
        width: 100%;
        transform: translateY(16px);

        span {
          width: 16px;
          font-size: 16px;
          line-height: 24px;
          color: #5755dd;
          text-align: center;

          &.lower {
            color: #f53c3c;
          }
          &.upper {
            color: #12b78f;
          }
        }
      }
    }
  }

  .slider-label {
    height: 48px;
    margin-top: 8px;
    position: relative;

    .slider-input {
      max-width: 72px;

      .ubt__input__field {
        height: 48px;
      }
    }
  }
}
