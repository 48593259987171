@import 'common/scss/utils.scss';

.description {
  .display-option {
    span {
      flex: 1;
    }
    p {
      color: $surface-high;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .edit-option {
    .option-wrapper {
      @include flex(center, initial);
      gap: 16px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid $gray-200;

      h6 {
        color: $surface-high;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }

      span {
        color: $surface-medium;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        flex: 1;
      }

      .ubt__input {
        width: 48px;

        .ubt__input__field {
          input {
            padding: 8px;
            color: $surface-high;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }
}
