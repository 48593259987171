@import 'common/scss/utils.scss';

#admin-manage-theme-content {
  .subscribe-setting-disabled {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: $surface-100;
    opacity: 0.7;
  }

  .admin-manage-theme-container {
    background-color: $surface-100;
    border-radius: 8px;
  }
}
