@import 'common/scss/utils.scss';

#admin-reviewer-table {
  .ubt__table {
    .ubt__table__wrapper {
      .ubt__table__body__wrapper {
        .ubt__table__container {
          .ubt__table__body {
            .ubt__table__row {
              .ubt__table__tr {
                .ubt__table__td {
                  overflow: initial;
                  .ubt__table__td__context {
                    .ubt__table__text__node {
                      overflow: initial;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .active-box {
      position: relative;
      .fill-lock {
        position: absolute;
        top: 50%;
        left: -30px;
        z-index: 1;
        transform: translateY(-50%);
      }
    }
    #ubt__no__data {
      display: none;
    }
  }
  // .jack_table__row {
  //   .jack_table__td {
  //     &:last-child {
  //       .jack_table__td__context {
  //         .text-node {
  //           width: 100%;
  //         }
  //       }
  //     }
  //   }
  //   .jack_table__tr {
  //     .admin-page-reviewer-table-active {
  //       @include flex(center, initial);
  //     }
  //   }
  // }

  .jack__no__data {
    .content__wrapper {
      .jack__no__data__text {
        &::before {
          content: '등록된 심사자 항목이 없습니다.';
        }
      }
    }
  }
}
