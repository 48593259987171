@import 'common/scss/utils.scss';

.not__allowed__page {
  @include flex(center, center);
  flex-direction: column;
  position: relative;
  background-color: $surface-100;
  height: 484px;
  color: $surface-medium;

  .icon {
    background: kyc-icon(-662px, -164px);
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
  }
}
