@import 'common/scss/utils.scss';

#manage-general-setting {
  margin-top: 40px;
  .section-title {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 16px;
    color: $surface-medium;
  }
  h2:not(:first-child) {
    margin-top: 40px;
  }
  section {
    padding: 16px;
    background: $surface-100;
  }
  .row {
    margin-bottom: 8px;

    section {
      flex: 1;
      border-radius: 8px;
    }
  }
}
