.jack__validator__error__message {
  white-space: nowrap;
  padding: 8px 10px;
  position: absolute;
  left: 0;
  // background-color: #6d040a;
  font-size: 11px;
  border-radius: 2px;
  color: #fff;
  z-index: 10;

  & > div {
    line-height: 20px;
  }

  & > div {
    line-height: 20px;
  }
}
