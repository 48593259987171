@import 'common/scss/utils.scss';

.month-content {
  width: 240px;
  .month-list {
    @include flex(center, space-between);
    gap: 8px;
    flex-wrap: wrap;
    width: 100%;
    & > div {
      cursor: pointer;
      width: min(116px, 100%);
      height: 32px;
      line-height: 32px;
      text-align: center;
      &:hover {
        background-color: #f6f6f6;
      }
      &.active {
        background-color: #2d2bd4;
        color: #ffffff;
      }
    }
  }
}
