@import 'common/scss/utils.scss';

section.reviewer-review-table-tr-expand {
  @include flex(initial, initial);
  gap: 6px;
  border-top: 3px solid $primary-5;
  // padding: 26px 30px;
  height: 100%;
  background-color: #f9f9ff;

  .content-wrapper {
    width: 100%;
    padding: 26px 30px;
    border-radius: 8px;
    overflow: hidden;
    background-color: $surface-100;
    border-radius: 0;

    & > ul {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 24px;

      li {
        .jack__accordion__section {
          .jack__accordion_button {
            height: 56px;
            border-radius: 8px;
            background-color: $gray-100;
            padding: 16px;

            &:is(.jack__accordion__section.confirmed .jack__accordion_button),
            &.active {
              background-color: $primary-5;
            }

            .title {
              @include flex(center, initial);
              gap: 8px;
              font-size: 16px;
              font-weight: 500;

              .jack__checkbox__container.circular {
                margin-right: 8px;
                padding: 0;
              }
            }

            .accordion__arrow__icon {
              right: 16px;
            }
          }

          .accordion__content {
            background-color: $surface-100;
          }
        }
      }
    }
  }
  & > article {
    @include flex(center, space-between);
    flex-direction: column;
    flex-shrink: 0;
    width: 262px;
    padding: 30px 0;
    background-color: $surface-100;

    .decision-buttons {
      button.jack__button {
        width: 202px;
        height: 48px;

        &:first-child {
          border: 1px solid $success-100;
          background-color: $success-10;
          color: $success-100;

          &.disabled {
            border: 1px solid $success-30;
            background-color: rgb(248, 253, 252);
            color: $success-30;
          }
        }

        &:last-child {
          margin-top: 16px;
          border: 1px solid $error-100;
          background-color: rgba(245, 60, 60, 0.1);
          color: $error-100;

          &.disabled {
            border: 1px solid $error-30;
            background-color: rgb(255, 249, 249);
            color: $error-30;
          }
        }
      }
    }

    & > p {
      @include flex(center, initial);
      font-size: 14px;
      color: rgb(134, 134, 134);
      margin-top: 30px;

      &::before {
        content: '';
        display: block;
        background: url('/assets/img/kyc_icons.svg') no-repeat -144px -196px;
        width: 17px;
        height: 17px;
        margin-right: 4px;
      }
    }
  }
}
