@import 'common/scss/utils.scss';

.edit_info__modal__wrapper {
  @mixin info-input {
    border: 1px solid #ced1d2;

    border-radius: 8px;
  }
  .name-check {
    @include flex(initial, initial);
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 20px;
    gap: 10px;
    .form__input__element {
      width: 310px;
    }

    .ubt__button {
      background-color: #868686;
      flex-shrink: 0;
      height: 56px;
      width: 84px;
    }

    .api-check-message {
      font-size: 0.75rem;
      color: $primary-100;
      &.error {
        color: $error-100;
      }
    }
  }
  .ubt__checkbox {
    .ubt__checkbox__label {
      cursor: pointer;
      .ubt__label__text {
        margin-bottom: 0;
        font-size: 0.75rem;
        line-height: 0.75rem;
        font-weight: 400 !important;
        color: #373eff;
      }
    }
  }

  .jack__checkbox__container {
    .jack__checkbox__label {
      font-size: 0.75rem;
      line-height: 0.75rem;
      // color: $surface-disabled;
    }
    &.require {
      .jack__checkbox__label {
        color: $primary-100;
      }
    }
  }
}
