@import 'common/scss/utils.scss';

.ra-criterion-logs {
  .criterion-content {
    @include flex(center, center);
    position: relative;
    min-height: 228px;
  }

  .jack_table__body {
    min-height: 164px !important;
  }

  .jack_table {
    .text-node {
      @include flex(initial, center);
      flex-direction: column;
      gap: 4px;

      div {
        @include flex(center, initial);
      }

      span {
        text-align: right;
        width: 40px;
        margin-left: auto;

        &:not(.after span):first-of-type {
          margin-left: 0;
          margin-right: 16px;
          font-size: 12px;
        }

        &.highlight {
          color: $primary-80;
        }

        i {
          width: 16px;
          height: 16px;
          background: kyc-icon(-64px, -45px);
          transform: translate(0, 2px);
          scale: 0.8;
        }
      }

      .after {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 16px;
          height: 16px;
          background: kyc-icon(-64px, -45px);
          transform: translate(0, 2px);
          scale: 0.8;
        }
      }
    }
  }
}
