@import 'common/scss/utils.scss';

#root-guidance-policy-table {
  .ubt__table__wrapper {
    .ubt__table__header__wrapper {
      .ubt__table__head {
        padding-left: 10%;
      }
    }
    .ubt__table__body__wrapper {
      .ubt__table__container {
        .ubt__table__body {
          .ubt__table__row {
            .ubt__table__tr {
              padding-left: 10%;
            }
          }
        }
      }
    }
  }

  .expand-icon {
    @include flex(center, center);
    width: 48px;
    height: 48px;
    cursor: pointer;

    &.expanded {
      &::before {
        background-position: -25px -101px;
      }
    }

    &::before {
      content: '';
      display: block;
      background: kyc-icon(-44px, -115px);
      width: 17px;
      height: 10px;
    }
  }
}
