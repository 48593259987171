@import 'common/scss/utils.scss';

// 변경로그 및 결재함 팝업에서 쓰는 변경내역 박스
.expand-comp {
  @include flex(center, space-between);
  padding: 0 12px 16px;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  & > p {
    width: 22px;
    text-align: center;
  }

  .status-view-comp {
    flex: 1;
    border-radius: 8px;
    border: 1px solid $gray-500;
    text-align: center;
    overflow: hidden;
    &.default {
      width: 200px;
      flex: none;
    }
    .title {
      color: $surface-medium;
      height: 32px;
      @include flex(center, center);
      background-color: $gray-100;
      border-bottom: 1px solid $gray-300;
    }
    .row {
      height: 56px;
      @include flex(center, center);
      .default-text {
        font-weight: 500;
        padding: 3px 10px;
        &.active {
          background-color: #fff9db;
          border-radius: 4px;
        }
      }

      .individual {
        @include flex(center, space-between);
        width: 100%;

        & > dl {
          @include flex(center, center);
          flex-direction: column;
          gap: 2px;
          flex: 1;
          height: 56px;
          border-right: 1px solid $gray-300;
          padding: 4px;
          line-height: 24px;

          &:last-of-type {
            border-right: none;
          }
          dt {
            font-weight: 500;
          }
          dd {
            padding: 0px 8px;
            text-wrap: nowrap;

            &.active {
              background-color: #fff9db;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
