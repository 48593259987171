@import 'common/scss/utils.scss';
body {
  #root-eddra-label-history {
    .expand-icon {
      @include flex(center, center);
      width: 48px;
      height: 48px;
      cursor: pointer;

      &.expanded {
        &::before {
          background-position: -25px -101px;
        }
      }

      &::before {
        content: '';
        display: block;
        background: kyc-icon(-44px, -115px);
        width: 17px;
        height: 10px;
      }
    }

    .ubt__table {
      .ubt__table__wrapper {
        .ubt__table__body__wrapper {
          .ubt__table__container {
            .ubt__table__body {
              .ubt__table__row {
                .ubt__table__tr {
                  height: 56px;
                }
              }
            }
          }
        }
      }
    }

    article.root-eddra-label-history-expand {
      padding: 16px;
      padding-top: 0;
      animation-name: expand;
      animation-duration: 0.3s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;

      .rowData {
        border: 1px solid $gray-200;
        border-bottom: 0;
        @include flex(initial, initial);

        &:first-of-type {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &:last-of-type {
          border-bottom: 1px solid $gray-200;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &.country__add {
          .value::before {
            content: '+';
            margin-right: 8px;
          }
        }
        &.country__delete {
          .value::before {
            content: '-';
            margin-right: 8px;
          }
        }

        span {
          display: inline-block;
          padding: 8px;

          &.label {
            border-right: 1px solid $gray-200;
            width: 116px;
            flex-shrink: 0;
            text-align: center;
          }
        }
      }
    }

    @keyframes expand {
      from {
        max-height: 0;
      }
      75% {
        max-height: 200px;
      }
      to {
        max-height: 500px;
      }
    }
  }
}
