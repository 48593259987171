@import 'common/scss/utils.scss';

.accordion__section {
  background-color: $primary-5;
  @include border($gray-200);
  padding: 0 16px;
  transition: box-shadow 0.3s ease, padding 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  &.expand {
    padding-bottom: 16px;
  }
  &.inactive {
    background-color: $gray-100;
    @include border($gray-300);
    color: $surface-medium !important;
    &.expand {
      box-shadow: unset;
    }

    div.accordion__content {
      background-color: inherit;
    }
    & .item__option {
      background-color: $gray-100;
    }
    .item__subtree {
      color: $surface-medium;
    }
    .item__subtree.footer {
      background-color: inherit;
    }
    .jack__switch__container {
      background-color: $surface-disabled;
    }
  }
  &.error {
    background-color: $error-10;
    &.expand {
      box-shadow: 0px 0px 8px rgba(255, 89, 116, 0.1), 0px 2px 8px 4px rgba(255, 89, 116, 0.05);
    }
  }

  .item__title {
    @include flex(center, initial);
    width: 100%;
    transition: color 0.3s ease;

    &:is(.accordion__section.inactive .item__title) {
      color: $surface-medium;
    }

    & > div {
      flex: 2;
      @include flex(center, center);
      &:nth-child(1) {
        @include flex(center, initial);
        gap: 16px;
      }
      &:nth-child(4) {
        flex: 1;
      }
    }

    .draggable[draggable='true'] {
      cursor: move;
    }

    i.item__drag__handle {
      background: kyc-icon(-260px, -166px);
      width: 17px;
      height: 22px;
      cursor: move;
    }

    .max__score {
      @include flex(center, flex-end);
      padding-right: 48px;
      gap: 8px;

      &.edit {
        color: $primary-100;
        font-weight: 500;
        input {
          font-weight: 500;
        }
      }

      &:is(.accordion__section.inactive .max__score) {
        color: $surface-medium;
      }

      div:not(.max__score.edit div) {
        width: 56px;
        text-align: right;
        font-weight: 500;
      }

      &.error {
        color: $error-100;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        color: $surface-medium;
        // margin-right: 24px;
      }
    }
  }
  .item__subtree {
    @include flex(center, initial);
    height: 48px;
    padding: 0 24px;
    border: 1px solid $gray-200;
    border-top: none;
    background-color: $surface-100;
    transition: background-color 0.3s ease;

    &:first-of-type {
      border: 1px solid $gray-200;
    }

    &:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.header {
      height: 36px;
      font-size: 14px;
      font-weight: 400;
      color: $surface-medium;

      &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
    &.footer {
      margin-top: 8px;
      padding: 0;
      background-color: inherit;
      border: none;
      height: auto;

      button {
        margin-left: auto;
        padding: 8px 12px;
        @include border($gray-200);
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: $surface-100;
        color: $surface-high;
      }
    }

    span {
      flex: 2;

      &:not(.score) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:last-child {
        flex: 1;
      }

      &.score {
        flex: 1;
        @include flex(center, center);
        position: relative;

        & div:not(.jack__input__container) {
          width: 44px;
          text-align: right;
        }
      }
    }
  }

  .item__option.description {
    position: relative;
    display: grid;
    grid-auto-columns: 1fr 2fr;
    grid-auto-flow: column;
    gap: 16px;
    padding: 8px 0;
    margin-bottom: 8px;

    &.edit {
      & > div:not(.description__guide) {
        grid-template-columns: 32px auto;
        row-gap: 8px;

        h4 {
          grid-column: 1/2;
          color: $surface-high;
          font-size: 14px;

          &:is(.accordion__section.inactive h4) {
            color: $surface-medium;
          }
        }
        span {
          grid-column: 2/3;
          font-size: 13px;
        }
        div {
          grid-column: 1/3;
        }

        .jack__input__container {
          &.disabled {
            .jack__input {
              background-color: $gray-100;
            }
          }
        }
      }
    }

    & > div:not(.description__guide) {
      display: grid;
      grid-template-columns: auto;
      color: $surface-medium;
      font-size: 14px;
      font-weight: 400;
    }

    .description__guide {
      position: absolute;
      top: 0;
      right: 0;
      color: $surface-medium;
      text-decoration: underline;
      font-weight: 400;

      .jack__button__context {
        gap: 4px;
      }

      i {
        width: 16px;
        height: 16px;
        background: kyc-icon(-196px, -318px);
        cursor: pointer;
        position: relative;
      }
    }

    .jack__input__container {
      .jack__input {
        font-size: 14px;
        line-height: 24px;
        height: initial;
        text-align: initial;
        padding: 10px;
        color: $surface-high;
      }
    }
  }

  .item__option:not(.description) {
    display: grid;
    grid-auto-columns: auto 1fr auto;
    grid-auto-flow: column;
    align-items: center;
    gap: 16px;

    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 12px 16px;
    background-color: $gray-100;
    margin-bottom: 8px;

    color: $surface-medium;

    &.active {
      background-color: $primary-5;
    }
    &.edit {
      background-color: $surface-100;
      color: $surface-high;
    }

    &:is(.accordion__section.inactive .item__option) {
      background-color: $gray-100;
    }

    h4 {
      font-weight: 400;
    }
    span {
      font-size: 14px;
      color: $surface-medium;
    }
    &:not(&.edit) {
      .jack__switch__container {
        opacity: 0.4;
      }
    }
  }

  .error__message {
    position: absolute;
    top: 0;
    left: -168px;
    font-size: 12px;
    padding: 8px;
    border-radius: 8px;
    color: $error-100;
    background-color: $gray-100;
  }

  .jack__accordion__section {
    .jack__accordion_button {
      font-size: 16px;
      height: 56px;
      padding: 0 8px;
      background-color: transparent;

      .accordion__remove__icon {
        // position: absolute;
        // right: 34px;
        width: 24px;
        height: 24px;
        background: kyc-icon(-3px, -351px);
        // transform: translateX(-16px);
        cursor: pointer;
        filter: grayscale(1);
      }

      &.active ~ .accordion__content {
        visibility: visible;
        color: $surface-high;
      }
    }

    .accordion__content {
      visibility: hidden;
      transition: max-height 0.3s ease, visibility 0.3s ease, background-color 0.3s ease;
      background-color: initial;
    }
  }

  .jack__input__container {
    &.basic {
      .jack__input {
        font-size: 16px;
        color: inherit;
        transition: none;
        text-align: right;
        padding-left: 0;
        padding-right: 14px;
        border-radius: 8px;
        height: 32px;
      }
    }

    &.disabled {
      .jack__input {
        background-color: $surface-100;
      }
    }
  }
}

#modal-ra-item-remove {
  .modal__basic__wrapper {
    .modal__content {
      padding: 8px 40px 16px;

      .detail {
        text-align: center;
      }
    }
    .modal__footer {
      .footer__submit {
        background-color: $error-100;
      }
    }
  }
}
