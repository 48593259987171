@import 'common/scss/utils.scss';

#idcarddriver__issue__date__wrapper {
  margin-top: 16px;
  text-align: center;

  .status-container {
    @include flex(initial, center);
    gap: 8px;

    .status {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $surface-high;
      &.ok {
        color: $primary-100;
        font-weight: 500;
      }
    }
    .icon {
      width: 16px;
      height: 12px;
      margin: 5px;
      background: kyc-icon(-64px, -47px);
    }

    .ubt__radio__group {
      width: 100%;

      &.issue__date_group {
        gap: 8px;

        .ubt__radio__item {
          @include flex(flex-start, initial);
          border: 1px solid $surface-disabled;
          border-radius: 8px;
          padding: 16px;
          text-align: initial;

          &.checked {
            border: 1px solid $primary-100;
          }

          .ubt__radio__box {
            @include flex(initial, initial);
            width: 150px;
          }

          .flex-container {
            border: 1px solid $surface-disabled;
            border-bottom: 0;
            font-size: 12px;
            width: 459px;
            & > .flex-row {
              @include flex(center, space-between);
              width: 100%;
              &.head {
                background-color: $gray-100;
              }
              & > div {
                padding-top: 2px;
                text-align: center;
                border-right: 1px solid $surface-disabled;
                border-bottom: 1px solid $surface-disabled;
                height: 40px;
                &.flex {
                  @include flex(center, center);
                  background-color: $surface-100;
                }
                &.last-flex {
                  flex: 1;
                  @include flex(center, center);
                  background-color: $surface-100;
                  border-right: 0;
                }

                &:nth-child(1) {
                  width: 93px;
                }
                &:nth-child(2) {
                  flex: 1;
                }
                &:nth-child(3) {
                  flex: 1;
                  border-right: 0;
                }
              }
            }
          }
        }
      }
    }

    .description {
      text-align: initial;
      height: 236px;
      width: 400px;
      font-size: 14px;
      background-color: $primary-5;
      padding: 16px;
      border-radius: 8px;
      p {
        line-height: 23px;
      }
      & > div {
        margin-bottom: 24px;
        h4 {
          margin-bottom: 8px;
        }
        p {
          padding-left: 6px;
          line-height: 24px;
        }

        &.grid-container {
          display: grid;
          grid-template-columns: 95px 182px 97px;
          grid-template-rows: 28px 28px 48px;
          justify-content: center;
          align-items: center;
          margin-bottom: 18px;
          .grid-item {
            height: 100%;
            @include flex(center, center);
            gap: 4px;
            text-align: center;
            background-color: $surface-100;
            border-bottom: 1px solid $surface-disabled;
            border-left: 1px solid $surface-disabled;
            font-size: 12px;

            &.header {
              text-align: left;
              background-color: $gray-100;
              justify-content: flex-start;
              padding-left: 8px;
            }
            &:first-child {
              grid-row: 1/3;
              border-top: 1px solid $surface-disabled;
            }
            &:nth-child(2) {
              border-top: 1px solid $surface-disabled;
            }
            &:nth-child(3) {
              border-top: 1px solid $surface-disabled;
              border-right: 1px solid $surface-disabled;
            }

            &:nth-child(5) {
              border-right: 1px solid $surface-disabled;
            }

            &:last-child {
              grid-column: 2/4;
              border-right: 1px solid $surface-disabled;
            }

            &.success {
              color: $success-80;
              font-weight: 700;
            }
            &.failed {
              color: $error-80;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  hr {
    background: $gray-300;
    margin: 16px 40px;
    border: none;
    height: 1px;
  }
}
