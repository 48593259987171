@import 'common/scss/utils.scss';

#dialog-account-lock-content {
  padding: 32px;

  .ubt__dialog__content {
    padding: 0;
  }
  .description {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    & > b {
      font-weight: 700;
    }
  }
}
