@import 'common/scss/utils.scss';
@import '../../SettingCard.scss';

#admin-manage-theme-setting {
  @include admin-manage-section {
    @include admin-manage-section-header;
    @include admin-manage-section-edit-control;

    @include description() {
      @include edit-option {
        @include flex(initial, initial);
        flex-direction: initial;
        padding: 24px;
        border: 1px solid $gray-200;
        border-radius: 8px;
        width: 100%;

        section {
          @include flex(initial, initial);
          flex-direction: column;
          flex: 1;

          &:last-child {
            border-left: 2px solid $gray-200;
          }
        }
      }
      @include display-option {
        padding: 16px;

        .option-container {
          @include flex(center, space-between);
          gap: 32px;
          flex: 1;

          .option {
            @include flex(center, space-between);
            flex: 1;
            position: relative;

            span {
              color: $surface-high;
            }

            .image {
              @include flex(center, center);
              border: 1px solid $gray-200;
              border-radius: 4px;
              padding: 8px;
              background-color: $surface-100;

              img {
                max-width: 100px;
                max-height: 20px;
              }
            }
            .theme-color,
            .font-color {
              @include flex(center, center);
              gap: 16px;
            }

            &:not(:first-of-type) {
              &::before {
                content: '';
                display: block;
                height: 36px;
                width: 1px;
                border-left: 1px solid $gray-200;
                border-right: 1px solid $gray-200;
                position: absolute;
                left: -16px;
              }
            }
          }
        }
      }
    }
  }

  &.admin-manage-section {
    .theme-controller {
      display: flex;
      width: 100%;
      flex: 1 1 50%;
      max-height: 0;
      overflow: hidden;
      visibility: hidden;

      &.active {
        max-height: 100vh;
        margin-top: 26px;
        overflow: hidden;
        visibility: visible;
      }
      .theme_preview {
        width: 100%;
      }
    }
  }
}
