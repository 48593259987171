@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@import './fonts.scss';
@import './elements/kyc_validator_error.scss';
@import './elements/kyc_table.scss';
@import './elements/kyc_alert.scss';
@import './elements/kyc_switch.scss';
@import './elements/kyc_input.scss';
@import './elements/kyc_button.scss';
@import './elements/kyc_radio.scss';
@import './elements/kyc_search.scss';
@import './elements/kyc_select.scss';
@import './elements/kyc_noData.scss';
@import './elements/kyc_textarea.scss';
@import './elements/kyc_checkbox.scss';
@import './elements/kyc_accordion.scss';
@import './elements/kyc_pagination.scss';

@import './usebeauty/kyc_ubt_input.scss';
@import './usebeauty/kyc_ubt_button.scss';
@import './usebeauty/kyc-ubt_select.scss';
@import './usebeauty/kyc-ubt_switch.scss';
@import './usebeauty/kyc-ubt-table.scss';
@import './usebeauty/kyc-ubt-dialog.scss';
@import './usebeauty/kyc-ubt-radio.scss';
@import './usebeauty/kyc-ubt-toggle.scss';
@import './usebeauty/kyc-ubt-checkbox.scss';
@import './usebeauty/kyc_ubt_textarea.scss';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

* {
  font-family: Pretendard, 'Noto Sans KR', sans-serif;
  z-index: 0;
}

body {
  color: #191919;
  background-color: $primary-5;
}

#root {
  width: 100vw;
  height: 100vh;
  color: #363636;
  overflow-x: hidden;
  overflow-y: hidden;
}
