@import 'common/scss/utils.scss';

#admin-login {
  @include flex(initial, center);
  position: relative;
  height: calc(100% - 48px);
  min-height: 800px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 64%;
    left: 0;
    top: 0;
    z-index: 0;
    background: url('/assets/admin-bg.svg') repeat-x;
  }

  #admin-login-form-field {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    z-index: 1;

    .signin-title {
      font-size: 32px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 32px;
      color: $surface-100;

      i {
        background: kyc-icon(-9px, -551px);
        width: 127px;
        height: 19px;
      }
    }

    section.form-section {
      width: 400px;
      padding: 40px;
      max-height: 100%;
      overflow: auto;
      background-color: $surface-100;
      border-radius: 8px;
      box-shadow: 0px 0px 8px rgb(95 101 255 / 10%), 0px 2px 8px 4px rgb(95 101 255 / 5%);

      .validator__form {
        .login-text {
          font-weight: bold;
          font-size: 24px;
          text-align: center;
          color: $primary-80;
          margin-bottom: 38px;
        }
        .error-message {
          border-radius: 8px;
          background-color: $error-10;
          padding: 8px 16px;
          color: $error-100;
          font-size: 12px;
          font-weight: 400;
          margin-top: 16px;
        }

        .customer-input {
          position: relative;
          .check {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
          }
        }

        .api-check-message {
          font-size: 12px;
          color: $primary-100;
          margin-top: 10px;
          &.error {
            color: $error-100;
          }
        }
        .jack__validator__error__message {
          /* Error / 10% */

          background: $error-10;
          border-radius: 8px;
          padding: 14px 16px !important;
        }

        .jack__validator__button {
          background-color: $primary-100;
          margin-top: 40px;
          margin-bottom: 30px;
        }

        .utils-field {
          @include flex(initial, space-between);
          margin-top: 30px;
          width: 100%;
          font-size: 12px;

          a:first-child {
            color: $primary-100;

            &::after {
              content: '';
              display: block;
              height: 1px;
              background-color: $primary-100;
            }
          }

          a:last-child {
            @include flex(center, initial);
            color: $surface-medium;

            &::after {
              content: '';
              display: block;
              width: 6px;
              height: 10px;
              background: url('/assets/img/kyc_icons.svg') no-repeat -115px -120px;
              margin-top: 2px;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
}
