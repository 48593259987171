@import 'common/scss/utils.scss';

.year-comp-content {
  padding: 16px;
  .year-comp-list {
    @include flex(center, initial);
    gap: 16px;
    flex-direction: column;
    margin-top: 16px;
    height: 272px;
    overflow-y: auto;
    & > div {
      width: 240px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #f0f0f0;
      }
      &.active {
        background-color: #2d2bd4;
        color: white;
      }
    }
  }
}
