@import 'common/scss/utils.scss';

.dashboard-set-period {
  padding: 16px;
  .react-datepicker {
    border: 0;
  }
  .date-input-row {
    gap: 12px;
    @include flex(initial, initial);
    gap: 16px;
    flex-direction: column;
    span.label {
      font-size: 12px;
      line-height: 12px;
      color: var(--surface-medium);
      & + div {
        width: 100%;
        margin-top: 8px;
      }
      & + div:is(.date-input-row.time span.label + div) {
        width: 112px;
        margin-top: 8px;
      }
    }
    .ubt__input {
      width: 100px;
      .ubt__input__field {
        height: 36px;
        border-radius: 4px;
        & > input {
          font-size: 14px;
          padding: 4px;
          border-radius: 4px;
          text-align: center;
        }
      }
    }

    .start-date,
    .end-date {
      @include flex(initial, initial);
      gap: 4px;
      .date,
      .time {
        flex: 1;
        & > .err {
          color: var(--error-100);
          font-size: 12px;
          margin-top: 6px;
          position: relative;
          height: 20px;
          width: 1px;

          & > p {
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    &.time {
      @include flex(initial, space-between);
      flex-direction: column;
      gap: 16px;

      .start-date,
      .end-date {
        @include flex(initial, initial);
        gap: 4px;
        .date,
        .time {
          flex: 1;

          & > .err {
            color: var(--error-100);
            font-size: 12px;
            margin-top: 6px;
            position: relative;
            height: 20px;
            width: 1px;

            & > p {
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    & > p {
      margin: 6px 0;
    }
  }

  .time-input-row {
    .time-input-checkbox {
      margin-top: 8px;
    }

    .date-time-input {
      margin-top: 8px;
    }

    .ubt__label {
      .ubt__label__text {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400 !important;
        color: var(--surface-high);
      }
    }
  }
}
