@import 'common/scss/utils.scss';

#root {
  .ubt__table {
    .ubt__table__wrapper {
      padding: 0;
      font-size: 14px;
      .ubt__table__header__wrapper {
        .ubt__table__head {
          height: 56px;
          border-bottom: 1px solid #5f65ff;
          .ubt__table__th {
            .ubt__table__th__context {
              color: #868686;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
      .ubt__table__body__wrapper {
        min-height: 720px;
        .ubt__table__container {
          height: 100%;
          .ubt__table__body {
            min-height: calc(100vh - 300px);
            #ubt__no__data {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              .content__wrapper {
                @include flex(center, initial);
                flex-direction: column;
                .ubt__no__data__icon {
                  background: kyc-icon(-306px, -159px);
                  width: 148px;
                  height: 108px;
                  transform: unset;
                }
                .ubt__no__data__text {
                  font-size: 16px;
                  font-weight: 400;
                  margin-top: 24px;
                  &::before {
                    display: block;
                    content: '데이터가 존재하지 않습니다.';
                  }
                }
              }
            }
            .ubt__table__row {
              height: 72px;
              border-bottom: 3px solid #f9f9ff;
              .ubt__table__tr {
                height: 72px;
              }
            }
          }
        }
      }
    }
  }
  .ubt__pagination {
    margin-top: 25px;
    .ubt__pagination__buttons {
      & > button {
        background-color: transparent;
      }
    }
  }
}
