@import 'common/scss/utils.scss';

.ubt__multitoggle {
  .ubt__multitoggle__container {
  }
}

.ubt__radio__group {
  @include flex(initial, space-between);
  flex-direction: column;
  gap: 8px;

  .ubt__radio__item {
    @include flex(initial, initial);
    gap: 8px;
    flex: 1;
    cursor: pointer;

    .ubt__radio__box {
      @include flex(center, initial);

      svg {
        // radio icon
        color: $primary-100;
      }

      .ubt__radio__text {
        // radio text
        color: $surface-high;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
      }
    }
  }
}
