@import 'common/scss/utils.scss';

body {
  .alchera-content-controller {
    .control-field {
      @include flex(initial, space-between);
      width: 100%;
      height: 106px;
      padding-top: 40px;

      & > h2 {
        @include flex(center, initial);
        gap: 32px;
        color: $primary-80;
        font-weight: 500;
        font-size: 24px;
        .ubt__popover__button {
          .ubt__select__icon__arrow {
            color: #363636;
          }
        }

        a {
          color: $surface-disabled;
          &.active {
            color: inherit;
          }
        }
      }

      .addon-field {
        @include flex(center, initial);
        gap: 8px;

        .ubt__select {
          height: 36px;
        }

        .jack__select {
          width: 112px;
          height: 36px;

          &.disabled {
            .jack__select__button {
              border-color: #cccccc !important;
              .button__title {
                color: #ccc !important;
              }
            }
          }

          .jack__select__button {
            border-radius: 8px;
            font-size: 14px;
            border-color: #868686;
          }

          .jack__select__options {
            top: 0 !important;
            border-top: 1px solid #5f65ff !important;
            border-radius: 8px !important;

            .jack__select__item {
              height: 36px;
              font-size: 14px;
              padding: 0 14px;
              border-bottom: 2px solid transparent;
            }
          }
        }

        .jack__search__form__field {
          width: 208px;
          height: 36px;
          .jack__input__container {
            .jack__input {
              border-radius: 8px;
              border-color: #868686;
            }
          }
        }
      }
    }
  }
  .ubt__popover__poper {
    .ubt__popover__poper__content {
      .control-option {
        .ubt__select__item {
          height: 36px;
          font-size: 14px;
        }
      }
    }
  }
}
