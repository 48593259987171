@import 'common/scss/utils.scss';

#subscribe-management-setting-privacy {
  position: relative;

  & > .ex {
    font-size: 13px;
    color: $surface-medium;
    margin-top: 8px;
  }

  & > .judge-type {
    width: 100%;
    margin-top: 16px;
    gap: 45px;
    height: 52px;
    padding: 16px;
    border: 1px solid $gray-200;
    border-radius: 8px;
    @include flex(center, space-between);

    & > .flex-box {
      @include flex(center, initial);
      gap: 24px;
      & > p {
        font-size: 13px;
        color: $surface-medium;
      }
      & > div {
        font-size: 14px;
        color: $surface-high;
        font-weight: 500;
      }
    }
  }

  .flex-box {
    @include flex(initial, space-between);
    .header {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $surface-high;
      .title {
        & > h4 {
          font-weight: 500;
          font-size: 16px;
          margin-right: 6px;
        }
        & > p {
          font-weight: 400;
          font-size: 14px;
          color: $surface-medium;
        }
      }
    }
    .used-text {
      width: 71px;
      @include flex(center, center);
      font-size: 14px;
      font-weight: 500;
      background-color: #f6f6f6;
      height: 34px;
      line-height: 34px;
      color: $surface-disabled;
      border-radius: 8px;
      &.active {
        color: $primary-80;
        background-color: transparent;
      }
    }
  }
}
