@import 'common/scss/utils.scss';

#hmac__dialog__wrapper {
  text-align: center;
  .modal__content {
    padding: 0px 45px;
  }
  .icon {
    background: kyc-icon(-662px, -94px);
    width: 63px;
    height: 63px;
  }

  .step-1 {
    .detail {
      padding: 0px 20px;
      color: $surface-high;
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      .accent {
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
  .step-2 {
    .hmac {
      @include flex(center, initial);
      padding: 16px 24px;
      border: 1px solid $gray-300;
      border-radius: 8px;
      margin-bottom: 16px;

      .hmac-value {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: $surface-high;
      }

      .ubt__button.copy {
        margin-left: auto;
        width: auto;
        font-size: 14px;
        font-weight: 500;
        color: $primary-80;
        text-decoration-line: underline;
        background-color: transparent;
      }
    }
    .hmac-detail {
      text-align: start;
      font-size: 0.8125rem;
      line-height: 1.25rem;
      color: $surface-medium;
      position: relative;
      margin-left: 24px;
      &::before {
        content: '';
        position: absolute;
        right: calc(100% + 6.5px);
        background: url('/assets/img/kyc_icons.svg') no-repeat -144px -196px;
        width: 17px;
        height: 17px;
      }
    }
  }
  .hmac-submit-btn {
    padding: 15px 16px;
    background: $primary-100;
    border-radius: 8px;
    flex: 1;

    font-weight: 500;
    font-size: 1rem;
    line-height: 1.4375rem;
    color: $surface-100;
  }
}
