@import 'common/scss/utils.scss';

.confidence-setting {
  .button-group {
    @include flex(center, flex-end);
    gap: 8px;

    button {
      padding: 8px 12px;
      border: 1px solid #ced1d2;
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background-color: #ffffff;
      color: #868686;

      &:last-child {
        color: #2d2bd4;
      }

      p {
        margin-left: auto;
        margin-right: 96px;
        font-weight: 400;
      }

      &[disabled] {
        color: #cccccc;
      }
    }
  }

  .confidence-label {
    @include flex(initial, space-between);
    gap: 2px;
    padding: 0 8px;

    span {
      color: #5755dd;
      word-break: keep-all;

      &:first-child {
        color: #f53c3c;
      }

      &:last-child {
        color: #12b78f;
      }
    }
  }
}
