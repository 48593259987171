@import 'common/scss/utils.scss';

.popover-date-filter {
  font-size: 14px;
  line-height: 24px;

  & > .action {
    padding: 16px;
    @include flex(center, flex-end);
    gap: 8px;
    .ubt__button {
      background-color: #ffffff;
      border: 1px solid #e4e6e7;
      border-radius: 8px;
      .ubt__button__context {
        color: #2d2bd4;
      }
      &.cancel {
        .ubt__button__context {
          color: #868686;
        }
      }
    }
  }
  .monthly {
    & > div {
      padding: 16px;
      &.selected-value {
        width: 100%;
        padding: 0;
        margin-bottom: 16px;
        @include flex(center, center);
        & > p {
          border: 1px solid #cccccc;
          border-radius: 8px;
          text-align: center;
          height: 36px;
          width: 150px;
          line-height: 35px;
        }
      }
      & > .react-datepicker {
        border: 0;
      }
    }
  }
  .action {
    & > .ubt__button {
      width: 50px;
      height: 36px;

      &[disabled] {
        color: var(--surface-disabled);
        background-color: var(--gray-100) !important;
        border-color: var(--gray-200);
        .ubt__button__context {
          color: var(--surface-disabled);
        }
      }
    }
  }
}
