@import 'common/scss/utils.scss';
@import '../../SettingCard.scss';

#admin-manage-examination-setting {
  .section-title {
    // font-weight: 500;
    // font-size: 1.25rem;
    // line-height: 2rem;
    // margin-bottom: 16px;
    // color: $surface-medium;
  }
  h2:not(:first-child) {
    // margin-top: 40px;
  }
  .setting-area {
    @include flex(initial, initial);
    flex-direction: column;
    gap: 8px;

    .setting-title {
      @include flex(center, initial);
      height: 36px;

      h3 {
        color: $surface-medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    section {
      @include admin-manage-section {
        @include admin-manage-section-header;
        @include admin-manage-section-edit-control;

        @include description {
          @include display-option;
          @include edit-option;
        }
      }
    }
  }
}
