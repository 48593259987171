@import 'common/scss/utils.scss';

#admin-manage-customfield-content {
  margin-top: 24px;
  .control-field {
    h2 {
      a {
        color: $surface-disabled;
        &.active {
          color: inherit;
        }
      }

      a:not(:first-child) {
        margin-left: 28px;
      }
    }
  }
  section#admin-manage-customfield-setting {
    // padding: 18px 32px;
    // background: $surface-100;
    @include flex(initial, initial);
    flex-direction: column;
    gap: 8px;

    .header {
      // display: block;
      height: 38px;
    }

    .title {
      h4 {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: $surface-medium;
      }
    }
  }
  .customfield-setting-disabled {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: $surface-100;
    opacity: 0.7;
  }
}
