@import 'common/scss/utils.scss';

.info-additional-wrap {
  margin-bottom: 32px;
  & > h4 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
    color: $surface-medium;
    margin-bottom: 8px;
  }
  & > .flex-box {
    @include flex(center, space-between);
    gap: 8px;
    & > .column {
      @include flex(center, space-between);
      background-color: $surface-100;
      flex: 1;
      padding: 16px;
      border-radius: 8px;

      .title {
        font-size: 16px;
        font-weight: 500;
      }
      .numbox {
        @include flex(center, initial);
        gap: 24px;
        .numbox-column {
          @include flex(center, initial);
          gap: 8px;
          & > p {
            font-size: 14px;
            font-weight: 400;
          }
          & > span {
            color: $surface-disabled;
            font-size: 14px;
            font-weight: 500;
            &.active {
              color: $primary-80;
            }
            &.unused {
              color: $primary-80;
            }
          }
        }
      }
    }
  }
}
