@import 'common/scss/utils.scss';

#kyc-page-token-timer {
  @include flex(center, initial);
  gap: 8px;
  margin-right: 16px;

  .timer {
    @include flex(center, initial);
    gap: 4px;
    width: 62px;
    font-size: 14px;
    line-height: 24px;
    color: $primary-80;
  }

  .divide {
    height: 16px;
    border-right: 1px solid $surface-disabled;
  }

  .extend {
    font-size: 14px;
    line-height: 24px;
    color: $surface-high;
    cursor: pointer;
  }
}
