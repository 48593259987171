@import 'common/scss/utils.scss';

.face-confidence-container {
  @include flex(initial, initial);
  flex-direction: column;
  gap: 8px;

  .face-confidence-setting-wrapper,
  .face-confidence-description-wrapper {
    border: 1px solid $gray-300;
    border-radius: 8px;
  }

  .face-confidence-setting-wrapper {
    padding: 24px;
  }

  .face-confidence-description-wrapper {
    @include flex(initial, initial);
    gap: 16px;
    padding: 16px 24px;

    .header {
      @include flex(center, initial);
      gap: 4px;
      width: 98px;
      height: 24px;

      h4 {
        font-size: 16px;
        line-height: 24px;
        color: $surface-high;
        font-weight: 500;
      }
    }

    .content {
      @include flex(initial, space-between);
      gap: 16px;
      flex: 1;

      &.single {
        .face-confidence-description-content {
          @include flex(initial, initial);
          gap: 16px;

          h4.description-title {
            @include flex(initial, flex-end);
            gap: 8px 16px;

            .detail {
              @include flex(flex-start, initial);
              gap: 16px;

              .description-content {
                flex-direction: row;
                gap: 16px;
              }
            }
          }
          .description-content {
            flex: 1;
          }
        }
      }

      .division {
        width: 1px;
        height: 100%;
        box-shadow: inset 1px 0px 0 0 $gray-300;
      }

      .face-confidence-description-content {
        flex: 1;
        position: relative;

        &.liveness {
          &::before {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            left: 49%;
            border-left: 1px solid $gray-300;
          }
        }

        .detail {
          @include flex(initial, space-between);
        }

        h4.description-title {
          @include flex(initial, space-between);
          column-gap: 8px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          color: $surface-high;
          flex-wrap: wrap;
          width: 152px;
          margin-bottom: 8px;
          &.liveness {
            position: absolute;
            top: 0;
            left: 58%;
            transform: translateX(-50%);
          }

          span.lower {
            color: $error-100;
          }
          span.upper {
            color: $success-100;
          }
        }

        .description-content {
          @include flex(initial, initial);
          gap: 8px;

          .description {
            @include flex(initial, initial);
            flex-direction: column;
            flex: 1;
            &.liveness {
              position: relative;
              .desc {
                position: absolute;
                top: 0;
                right: 32px;
              }
            }

            p.title {
              @include flex(center, initial);
              gap: 12px;
              font-size: 16px;
              line-height: 24px;
              color: $surface-high;
            }

            p.desc {
              font-size: 14px;
              line-height: 24px;
              font-weight: 400;
              color: $surface-medium;
            }
          }
        }
      }
    }
  }
}
