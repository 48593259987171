@import 'common/scss/utils.scss';

.edd-setting-nation-risk {
  @mixin error {
    .error {
      color: $error-100;
    }
  }

  .title {
    padding: 0 8px;
    @include flex(center, initial);
    height: 24px;
    color: $surface-high;
    cursor: pointer;

    h4 {
      font-weight: 700;
    }
    .score {
      @include flex(center, flex-end);
      gap: 24px;
      flex: 1;
      margin-right: 24px;

      .edit {
        visibility: hidden;
      }

      div {
        @include flex(center, initial);
        gap: 32px;

        &:first-child {
          span {
            color: $surface-high;
          }
        }

        span:first-child {
          color: $surface-medium;
          font-size: 14px;
          font-weight: 400;
        }
        span:last-child {
          font-size: 16px;
          font-weight: 500;
        }

        .extra-score {
          color: $primary-100;
        }

        @include error;
      }
    }
    i {
      width: 18px;
      height: 18px;
      background: kyc-icon(-24px, -110px);
      transform: rotate(270deg);
      cursor: pointer;
    }
  }

  .hide {
    visibility: hidden !important;
  }
}
