@import 'common/scss/utils.scss';

#root-privacy-check-info {
  .table-head {
    @include flex(center, space-between);
    margin-top: 40px;
    height: 66px;
    & > h4 {
      font-size: 24px;
      font-weight: 500;
      color: $primary-80;
    }
  }
  .select-area {
    @include flex(center, space-between);
    gap: 12px;
    min-width: 210px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding: 8px 10px;
    font-size: 16px;
    // line-height: 22px;
    background-color: white;
    & > .close {
      background: kyc-icon(-4px, -202px);
      margin-top: 1px;
      width: 11px;
      height: 10px;
    }
    & > .date-title {
      @include flex(center, space-between);
      width: 100%;
      & > p {
        color: $surface-medium;
        font-size: 16px;
      }
      & > i {
        background: kyc-icon(-24px, -44px);
        width: 18px;
        height: 18px;
      }
    }
  }
}
