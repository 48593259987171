@import 'common/scss/utils.scss';

body {
  .form__textarea__element {
    width: 100%;
  }

  .ubt__textarea {
    width: 100%;
    height: 100%;
    .ubt__textarea__field {
      width: 100%;
      height: 100%;
      font-size: 14px;
      border: 1px solid #e4e6e7;
      border-radius: 8px;
      &:has(textarea:focus) {
        border-color: #5f65ff;
      }
    }
  }
}
