@import 'common/scss/utils.scss';

#root-eddra-nation-label {
  margin-top: 16px;
  @include flex(initial, initial);
  flex-direction: column;
  gap: 16px;

  & > article {
    padding: 24px 16px;
    background-color: $surface-100;
  }
}
