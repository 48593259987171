@import 'common/scss/utils.scss';

#wasm-manual-shot {
  @include flex(initial, initial);
  flex-direction: column;
  gap: 8px;

  .wasm-radio-text {
    font-size: 14px;
    color: $surface-high;
    & > b {
      font-weight: 700;
    }
  }
  .ubt__radio__group {
    &.wasm-radio-group {
      flex-direction: initial;
      gap: 8px;

      .ubt__radio__item {
        flex-direction: column;
        gap: 4px;
        padding: 8px 16px;
        border: 1px solid $gray-200;
        border-radius: 8px;
        flex: 1;

        &.checked {
          border-color: $primary-100;
        }

        & > p {
          color: $surface-medium;
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }
}
