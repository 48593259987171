@import 'common/scss/utils.scss';

#subscribe-management-setting-message {
  position: relative;

  .result-message {
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 16px;
    @include flex(center, initial);
    margin-top: 16px;
    & > .title {
      width: 180px;
      font-weight: 500;
    }
    & > dl {
      flex: 1;
      font-size: 14px;
      dt {
        color: $surface-medium;
        margin-bottom: 4px;
      }
      dd {
        line-height: 24px;
      }
    }
  }

  .flex-box {
    @include flex(center, space-between);
    .header {
      @include flex(center, initial);
      font-size: 1rem;
      line-height: 1.5rem;
      color: $surface-high;
      .title {
        & > h4 {
          font-weight: 500;
          font-size: 16px;
          margin-right: 6px;
        }
        & > p {
          font-weight: 400;
          font-size: 14px;
          color: $surface-medium;
        }
      }
    }
  }

  .content {
    margin-top: 16px;
    .kyc__radio__group__container {
      .radio {
        @include flex(initial, initial);
        gap: 8px;
        flex-direction: column;
        .jack__radio__item {
          display: grid;
          grid-template-columns: 190px 1fr 1fr;
          border: 1px solid $gray-200;
          padding: 16px;
          border-radius: 8px;
          column-gap: 16px;
          .jack__radio__box {
            width: 120px;
            font-weight: 500;
            font-size: 14px;
          }
          & > dl {
            line-height: 24px;
            & > dt {
              @include flex(center, space-between);
              color: $surface-medium;
              font-size: 14px;
              & > h5 {
                font-size: 13px;
                font-weight: 400;
              }
              & > p {
                font-size: 13px;
              }
            }
            & > dd {
              font-size: 14px;
              & > .text-area {
                width: 100%;
                height: 100%;
                textarea {
                  height: auto;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
}
