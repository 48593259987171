@import 'common/scss/utils.scss';

#delet_hmac__modal__wrapper {
  text-align: center;

  .icon {
    background: kyc-icon(-662px, -94px);
    width: 63px;
    height: 63px;
  }

  .ubt__dialog__actions .ubt__button {
    &.negative {
      background-color: $error-100;
      color: $surface-100;
    }
  }
}
