@import 'common/scss/utils.scss';

.search-box {
  position: relative;
  .filter-search {
    .ubt__input__field {
      width: 230px;
      height: 36px;
      & > input {
        padding-right: 40px;
        padding-left: 16px;
        font-size: 14px;
      }
    }
  }
  .search-button {
    @include flex(center, center);
    border: 0;
    position: absolute;
    top: 52%;
    right: 5px;
    background-color: transparent;
    transform: translateY(-50%);
    padding: 5px;
  }
  .search-close-btn {
    @include flex(center, center);
    border: 0;
    position: absolute;
    top: 50%;
    right: 5px;
    background-color: transparent;
    transform: translateY(-50%);
    padding: 5px;
  }
  .search-button {
    &[disabled] {
      i {
        opacity: 0.4;
      }
    }
    i {
      width: 16px;
      height: 16px;
      background: kyc-icon(-84px, -69px);
    }
  }
}
