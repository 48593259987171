@import 'common/scss/utils.scss';

@mixin admin-manage-section() {
  &.admin-manage-section {
    @include flex(initial, initial);
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    padding: 16px;
    background-color: $surface-100;
    position: relative;

    @content;
  }
}

@mixin admin-manage-section-header() {
  .flex-box {
    @include flex(center, space-between);

    .header {
      @include flex(center, initial);
      font-size: 16px;
      line-height: 24px;
      color: $surface-high;
      flex: 1;

      .title {
        line-height: 24px;
        color: $surface-medium;

        & > h4 {
          @include flex(center, initial);
          gap: 4px;
          font-weight: 500;
          font-size: 16px;

          &.active {
            color: $surface-high;
          }
        }
        & > p {
          font-weight: 400;
          font-size: 14px;
          margin-top: 4px;
        }
      }

      i.header-tooltip {
        width: 18px;
        height: 18px;
        background: kyc-icon(-144px, -195px);
        cursor: pointer;
      }
    }
  }
}

@mixin admin-manage-section-edit-control() {
  .status-label {
    @include flex(center, center);
    width: 71px;
    height: 34px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    background-color: $gray-100;
    border-radius: 8px;
    color: $surface-disabled;
    margin-right: 16px;

    &.active {
      color: $primary-80;
      background-color: transparent;
    }
  }

  .status-toggle {
    @include flex(center, center);
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    .status-toggle-label {
      width: 55px;
      font-weight: 500;
      margin-right: 16px;
      color: $surface-disabled;

      &.active {
        color: $primary-80;
      }
    }
  }

  .button-group {
    @include flex(center, initial);
    gap: 8px;

    .ubt__button {
      box-shadow: none;
      padding: 0;
      .ubt__button__context {
        font-weight: 500;
      }

      font-size: 14px;
      width: 50px;
      height: 36px;
      border: 1px solid $gray-200;
      background-color: $surface-100;
      color: $surface-high;

      &.submit {
        color: $primary-80;
      }
      &.cancel {
        color: $surface-medium;
      }
      &.negative {
        color: $error-100;
      }
    }
  }
}

@mixin description() {
  .description {
    @content;
  }
}

@mixin display-option() {
  .display-option {
    @include flex(center, initial);
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $gray-200;

    h6 {
      color: $surface-high;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
    }

    span {
      color: $surface-medium;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
    }

    @content;
  }
}

@mixin edit-option() {
  .edit-option {
    @include flex(initial, initial);
    flex-direction: column;
    gap: 8px;

    p {
      color: $surface-high;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
    }

    @content;
  }
}
