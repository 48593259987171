@import 'common/scss/utils.scss';

#dialog-account-lock-content {
  padding: 32px;

  .description {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    & > b {
      font-weight: 700;
    }
  }
  .ubt__dialog__actions {
    padding: 0;
    .ubt__button {
      flex: 1;
    }
  }
}
