@import 'common/scss/utils.scss';

.static-chip {
  margin-right: 16px;
  position: relative;
  .check-icon {
    visibility: hidden;

    width: 23px;
    height: 23px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.3);
    background: kyc-icon(-20.5px, -246px);
  }
}
