@import 'common/scss/utils.scss';

#root-admin-table {
  position: relative;

  .control-option {
    .ubt__select__item {
      height: 36px;
      font-size: 14px;
    }
  }
  .ubt__table {
    .ubt__table__wrapper {
      .ubt__table__body__wrapper {
        .ubt__table__container {
          .ubt__table__body {
            .ubt__table__row {
              .ubt__table__tr {
                .root-page-admin-table-active {
                  @include flex(center, initial);
                }

                .root-page-admin-table-privacy {
                  @include flex(center, center);

                  &.match {
                    i {
                      background: kyc-icon(-273px, -218px);
                    }
                  }

                  i {
                    background: kyc-icon(-273px, -192px);
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                  }
                }

                .ubt__table__td {
                  .ubt__table__td__context {
                    .ubt__table__text__node {
                      overflow: initial;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .active-box {
      position: relative;
      .fill-lock {
        position: absolute;
        top: 50%;
        left: -30px;
        z-index: 1;
        transform: translateY(-50%);
      }
    }
    #ubt__no__data {
      display: none;
    }
  }
}

.jack__tooltip__root.warnlabel-tooltip-root {
  color: $surface-medium;
  background-color: $surface-100;
  border-radius: 8px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  #modal-warnlabel-tooltip {
    @include flex(flex-start, initial);
    flex-direction: column;
    gap: 8px;
    padding: 8px 4px;

    span {
      @include flex(flex-start, initial);
      flex-direction: column;
      gap: 8px;
      padding: 0px 8px;
      font-size: 12px;
      line-height: 22px;
      font-weight: 400;
      color: $surface-medium;
      border: 1px solid $surface-disabled;
      border-radius: 100px;
    }
  }
}
