@import 'common/scss/utils.scss';

.risk-detail-container {
  position: relative;
  top: 0;
  left: 100%;
  width: 100%;
  background-color: $surface-100;
  transition: left 0.2s ease;
  border-radius: 8px;

  &.detail {
    top: -401px;
    left: 0;
  }

  & > article {
    padding: 24px;
  }
}
